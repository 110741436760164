import React, { useEffect, useState } from "react";
import axios from "axios";
import { Admin_CRUD_SUBSCRIPTION_TRANSATIONS_URL } from "../../hooks/APIHooks";
import { useAuth } from "../../hooks/GlobalProvider";

const SubscriptionTransactions = () => {
  const { user } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [deleteError, setDeleteError] = useState(null);

  useEffect(() => {
    // Check if the user is an admin
    if (user && user.role === "admin") {
      axios
        .get(Admin_CRUD_SUBSCRIPTION_TRANSATIONS_URL)
        .then((response) => {
          setTransactions(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setError("Failed to fetch transactions");
          setLoading(false);
        });
    } else {
      setError("Unauthorized access");
      setLoading(false);
    }
  }, [user]);

  const handleViewClick = (transaction) => {
    setSelectedTransaction(transaction);
    setDeleteError(null);
  };

  const handleDeleteClick = (transactionId) => {
    if (window.confirm("Are you sure you want to delete this transaction?")) {
      axios
        .delete(
          `${Admin_CRUD_SUBSCRIPTION_TRANSATIONS_URL}?id=${transactionId}`
        )
        .then(() => {
          setTransactions(
            transactions.filter((trans) => trans.id !== transactionId)
          );
          setSelectedTransaction(null);
        })
        .catch((err) => {
          setDeleteError("Failed to delete the transaction.");
        });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mx-auto p-4 mt-20">
      <h1 className="text-2xl font-bold mb-4">Subscription Transactions</h1>
      <table className="min-w-full table-auto">
        <thead>
          <tr className="bg-gray-200">
            <th className="px-4 py-2">Transaction ID</th>
            <th className="px-4 py-2">Subscription ID</th>
            <th className="px-4 py-2">User ID</th>
            <th className="px-4 py-2">Amount</th>
            <th className="px-4 py-2">Status</th>
            <th className="px-4 py-2">Date</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {transactions.length > 0 ? (
            transactions.map((transaction) => (
              <tr key={transaction.id} className="border-b">
                <td className="px-4 py-2">{transaction.id}</td>
                <td className="px-4 py-2">{transaction.subscription_id}</td>
                <td className="px-4 py-2">{transaction.user_id}</td>
                <td className="px-4 py-2">{transaction.amount}</td>
                <td className="px-4 py-2">{transaction.status}</td>
                <td className="px-4 py-2">
                  {new Date(transaction.created_at).toLocaleDateString()}
                </td>
                <td className="px-4 py-2">
                  <button
                    className="bg-blue-500 text-white px-2 py-1 rounded"
                    onClick={() => handleViewClick(transaction)}
                  >
                    View
                  </button>
                  <button
                    className="bg-red-500 text-white px-2 py-1 rounded ml-2"
                    onClick={() => handleDeleteClick(transaction.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center py-4">
                No transactions found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {selectedTransaction && (
        <div className="mt-10 p-4 border rounded bg-gray-100">
          <h2 className="text-xl font-bold">Transaction Details</h2>
          <p>
            <strong>Transaction ID:</strong> {selectedTransaction.id}
          </p>
          <p>
            <strong>Subscription ID:</strong>{" "}
            {selectedTransaction.subscription_id}
          </p>
          <p>
            <strong>User ID:</strong> {selectedTransaction.user_id}
          </p>
          <p>
            <strong>Amount:</strong> {selectedTransaction.amount}
          </p>
          <p>
            <strong>Status:</strong> {selectedTransaction.status}
          </p>
          <p>
            <strong>Date:</strong>{" "}
            {new Date(selectedTransaction.created_at).toLocaleDateString()}
          </p>
          <p>
            <strong>Email:</strong> {selectedTransaction.email}
          </p>
          <p>
            <strong>Is Complete:</strong>{" "}
            {selectedTransaction.is_complete ? "Yes" : "No"}
          </p>
          {/* Handle delete errors */}
          {deleteError && <p className="text-red-500 mt-4">{deleteError}</p>}
        </div>
      )}
    </div>
  );
};

export default SubscriptionTransactions;
