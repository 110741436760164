import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RiArrowGoBackFill, RiStore3Fill } from "react-icons/ri";


function Productpost() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [makingPercentage, setMakingPercentage] = useState('');
    const [referralCommission, setReferralCommission] = useState('');
    const [productCatalogue, setProductCatalogue] = useState('Gold Coin');
    const [weight, setWeight] = useState('');
    const [image, setImage] = useState(null);
    const [products, setProducts] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const categories = [
        "Gold Coin", "Silver Coin", "Chains", "Blackbeads", "Ear Tops and Hangings",
        "Finger Rings", "Bangles", "Bracelets and Kadas", "Bajubandh", "Necklaces",
        "Haram", "Vaddanam", "Jadas", "Light weight", "Nose pin", "Diamond",
        "Bridal", "Platinum", "Ear Rings"
    ];

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await fetch('https://batchugold.com/apis/Store/ProductPost.php');
            const data = await response.json();
            setProducts(data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('making_percentage', makingPercentage);
        formData.append('referral_commission', referralCommission);
        formData.append('product_catalogue', productCatalogue);
        formData.append('weight', weight);
        formData.append('image', image);

        try {
            const response = await fetch('https://batchugold.com/apis/Store/ProductPost.php', {
                method: 'POST',
                body: formData
            });

            const data = await response.text();
            console.log(data);
            fetchProducts();
            resetForm();

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleRemove = async (id) => {
        try {
            const response = await fetch(`https://batchugold.com/apis/Store/ProductPost.php?id=${id}`, {
                method: 'DELETE'
            });

            const data = await response.text();
            console.log(data);
            fetchProducts();

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const resetForm = () => {
        setTitle('');
        setDescription('');
        setMakingPercentage('');
        setReferralCommission('');
        setProductCatalogue('Gold Coin');
        setWeight('');
        setImage(null);
        document.getElementById('fileInput').value = null;
    };

    const toggleCategory = (category) => {
        setSelectedCategories((prevSelected) =>
            prevSelected.includes(category)
                ? prevSelected.filter((item) => item !== category)
                : [...prevSelected, category]
        );
    };

    const filteredProducts = products.filter((product) =>
        selectedCategories.length === 0 || selectedCategories.includes(product.product_catalogue)
    );

    return (
        <section className="p-4 my-20">
      
            <div className="mb-4">
                <h2 className='text-2xl font-bold mb-2'>Upload Product</h2>
                <form onSubmit={handleSubmit} className='space-y-4'>
                    {/* Form fields for product details */}
                    <div className='flex flex-col mb-2'>
                        <label className='font-semibold'>Title:</label>
                        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required className='p-2 border rounded' />
                    </div>
                    <div className='flex flex-col mb-2'>
                        <label className='font-semibold'>Description:</label>
                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} required className='p-2 border rounded'></textarea>
                    </div>
                    <div className='flex flex-col mb-2'>
                        <label className='font-semibold'>Making Percentage:</label>
                        <input type="number" value={makingPercentage} onChange={(e) => setMakingPercentage(e.target.value)} step="0.01" required className='p-2 border rounded' />
                    </div>
                    <div className='flex flex-col mb-2'>
                        <label className='font-semibold'>Referral Commission:</label>
                        <input type="number" value={referralCommission} onChange={(e) => setReferralCommission(e.target.value)} step="0.01" required className='p-2 border rounded' />
                    </div>
                    <div className='flex flex-col mb-2'>
                        <label className='font-semibold'>Product Catalogue:</label>
                        <select value={productCatalogue} onChange={(e) => setProductCatalogue(e.target.value)} required className='p-2 border rounded'>
                            {categories.map((category) => (
                                <option key={category} value={category}>{category}</option>
                            ))}
                        </select>
                    </div>
                    <div className='flex flex-col mb-2'>
                        <label className='font-semibold'>Weight (g):</label>
                        <input type="number" value={weight} onChange={(e) => setWeight(e.target.value)} step="0.01" required className='p-2 border rounded' />
                    </div>
                    <div className='flex flex-col mb-2'>
                        <label className='font-semibold'>Image:</label>
                        <input id="fileInput" type="file" accept="image/*" onChange={(e) => setImage(e.target.files[0])} required className='p-2 border rounded' />
                    </div>
                    <button type="submit" className='bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600'>Upload Product</button>
                </form>
            </div>

            <div className='mb-4'>
                <h2 className='text-xl font-bold mb-2'>Product List</h2>
                <div className='mb-4'>
                    <h3 className='font-semibold mb-2'>Filter by Product Catalogue:</h3>
                    <div className='flex flex-wrap'>
                        {categories.map((category) => (
                            <label key={category} className='mr-4'>
                                <input
                                    type="checkbox"
                                    value={category}
                                    onChange={() => toggleCategory(category)}
                                    className='mr-2'
                                />
                                {category}
                            </label>
                        ))}
                    </div>
                </div>
                <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                    {filteredProducts.map((product) => (
                        <div key={product.id} className='bg-white border border-gray-200 rounded-lg shadow p-4'>
                            <h3 className='text-xl font-semibold mb-2'>{product.title}</h3>
                            <p className='mb-2 text-[12px]'><p className='font-bold'>Description</p> {product.description}</p>
                            <p className='mb-2 text-[12px]'><p className='font-bold'>Making Percentage:</p> {product.making_percentage}%</p>
                            <p className='mb-2 text-[12px]'><p className='font-bold'>Referral Commission:</p> {product.referral_commission}%</p>
                            <p className='mb-2 text-[12px]'><p className='font-bold'>Product Catalogue:</p> {product.product_catalogue}</p>
                            <p className='mb-2 text-[12px]'><p className='font-bold'>Weight:</p> {product.weight} g</p>
                            <img
                                src={`data:image/jpeg;base64,${product.image_data}`}
                                alt={product.title}
                                className="w-full h-32 object-cover rounded mb-2"
                            />
                            <button
                                onClick={() => handleRemove(product.id)}
                                className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600 w-full"
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                </div>
            </div>
              {/* Navigation Links */}
          <div className="flex justify-center fixed bottom-0 w-full p-2 z-10">
            <div className="flex flex-row justify-around w-full max-w-md p-2 bg-yellow-500 mx-2 rounded-full">
              <div className="flex flex-col items-center group">
                <Link
                  to="/user/dashboard"
                  className="flex flex-col items-center px-4 rounded-full text-white"
                >
                  <RiArrowGoBackFill className="h-6 w-6 mb-1 text-white" />
                  <span className="text-[10px]">BACK</span>
                </Link>
              </div>
              <div className="flex flex-col items-center group">
                <Link
                  to="/"
                  className="flex flex-col items-center px-4 rounded-full text-white"
                >
                  <RiStore3Fill className="h-6 w-6 mb-1 text-white" />
                  <span className="text-[10px]">HOME</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
    );
}

export default Productpost;
