import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { TheGoldJar1080p } from "../../assets/data/Imagedata";
import { toWords } from "number-to-words"; // Import the library
import ReactToPrint from "react-to-print";

const Invoice = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const invoiceRef = useRef();

  const orderList = params.get("orderlist") ? params.get("orderlist").split(",") : [];
  const quantities = params.get("quantity") ? params.get("quantity").split(",") : [];
  const amounts = params.get("amount") ? params.get("amount").split(",") : [];
  const totalAmount = amounts.reduce((total, amount) => total + parseFloat(amount || 0), 0);
  const totalAmountInWords = toWords(totalAmount); // Convert the total amount to words

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();
  const formattedTime = currentDate.toLocaleTimeString();

  return (
    <div className="relative mt-20 p-4 sm:p-6 bg-white shadow-lg rounded-md overflow-hidden max-w-full sm:max-w-[210mm] mx-auto">
      <div ref={invoiceRef} className="p-4 sm:p-6 bg-white">
        {/* Watermark Image */}
        <img
          src={TheGoldJar1080p}
          alt="Watermark"
          className="absolute inset-0 w-[50%] sm:w-[15%] h-auto object-cover opacity-10 mx-auto sm:mt-0 mt-32"
          style={{ zIndex: 0 }}
        />

        {/* Invoice Content */}
        <div className="relative z-10 flex flex-wrap">
          <div className="w-full sm:w-1/2 p-2 sm:p-4">
            <div className="flex">
              <img
                src={TheGoldJar1080p}
                alt="TheGoldJar1080p"
                className="w-[50%] md:w-[20%] h-auto mb-4"
              />
            </div>
            <p className="text-sm mb-1">From</p>
            <p className="text-sm mb-1">3-4-12/11, Jagadeesh Market,</p>
            <p className="text-sm mb-1">Mahankali Temple Street, General Bazaar,</p>
            <p className="text-sm mb-1">Secunderabad, Telangana-03.</p>
            <p>
              <strong>Transaction ID:</strong> <span className="text-sm">{params.get("transactionId")}</span>
            </p>
            <p>
              <strong>Created At:</strong> <span className="text-sm">{params.get("createdAt")}</span>
            </p>
            <p>
              <strong>Email:</strong> <span className="text-sm">{params.get("email")}</span>
            </p>
            <p>
              <strong>Phone Number:</strong> <span className="text-sm">{params.get("mobileNumber")}</span>
            </p>
          </div>
          <div className="w-full sm:w-1/2 p-2 sm:p-4">
            <div>
              <h1 className="text-[24px] sm:text-[38px] font-bold mb-4">Invoice</h1>
              <p className="text-sm mb-1">To</p>
              <p className="text-sm">{params.get("message")}</p>
            </div>
          </div>
        </div>

        {/* Table for Order List, Quantity, Amount */}
        <div className="mt-4 sm:mt-6 overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-gray-100 border-b text-[12px]">
                <th className="py-2 px-2 sm:px-4 text-left">Sr. No.</th>
                <th className="py-2 px-2 sm:px-4 text-left">Order List</th>
                <th className="py-2 px-2 sm:px-4 text-left">Quantity</th>
                <th className="py-2 px-2 sm:px-4 text-left">Amount</th>
              </tr>
            </thead>
            <tbody>
              {orderList.map((item, index) => (
                <tr key={index} className="border-b text-[12px]">
                  <td className="py-2 px-2 sm:px-4">{index + 1}</td>
                  <td className="py-2 px-2 sm:px-4">{item}</td>
                  <td className="py-2 px-2 sm:px-4">{quantities[index] || '-'}</td>
                  <td className="py-2 px-2 sm:px-4">{amounts[index] || '-'}</td>
                </tr>
              ))}
              <tr  className="border-b text-[12px]">
                <td className="py-2 px-2 sm:px-4"></td>
                <td className="py-2 px-2 sm:px-4"></td>
                <td className="py-2 px-2 sm:px-4">CGST</td>
                <td className="py-2 px-2 sm:px-4">1.5%</td>
              </tr>
              <tr  className="border-b text-[12px]">
                <td className="py-2 px-2 sm:px-4"></td>
                <td className="py-2 px-2 sm:px-4"></td>
                <td className="py-2 px-2 sm:px-4">SGST</td>
                <td className="py-2 px-2 sm:px-4">1.5%</td>
              </tr>
              <tr className="font-bold text-[15px]">
                <td className="py-2 px-2 sm:px-4 " colSpan="3">Total </td>
                <td className="py-2 px-2 sm:px-4">
                  {totalAmount.toFixed(2)}
                  <p className="text-[10px] font-normal">(including all charges)</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="text-[10px] text-center mt-2">We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct</p>
          {/* Box for Total Amount and Total Amount in Words */}
          <div className="p-4 bg-gray-100 border border-gray-300 rounded-md mt-4">
            <p className="flex items-center">
              <span className="font-bold">Total Invoice Amount:</span>
              <span className="ml-2 px-2">{totalAmount.toFixed(2)}</span>
              <span className="text-[10px] font-normal">(including all charges)</span>
            </p>
            <p className="flex items-center mt-2">
              <span className="font-bold">Total Invoice Amount in Words:</span>
              <span className="ml-2 px-2">{totalAmountInWords}</span>
            </p>
            <p className="flex items-center mt-2">
              <span className="font-bold">GSTIN/UIN:</span>
              <span className="ml-2 px-2">36BUTPD5542C1ZN</span>
            </p>
          </div>

          {/* Box for Additional Information */}
          <div className="flex space-x-4 pt-2">
            <div className="w-1/2 bg-gray-100 border border-gray-300 rounded-md p-2">
              <p className="text-black text-[10px]">Type of Order: <strong>Online order</strong></p>
              <p className="text-black text-[8px]">
                Dear Valued Customer,
                <br /><br />
                We take great care in ensuring that your gold coins are securely packaged to reach you in perfect condition. Please follow these guidelines upon receiving your parcel:
                <br /><br />
                Inspect Your Package: Before accepting the parcel from the delivery personnel, please inspect the outer packaging for any signs of tampering or damage.
                <br /><br />
                Do Not Accept Open Packages: If you notice that the package is open or appears to have been tampered with, please refuse to accept it and contact us immediately.
                <br /><br />
                Keep the Invoice: Ensure you keep this invoice safe as proof of purchase for future reference.
                <br /><br />
                Thank you for choosing our service. If you have any questions or concerns, feel free to contact us.
              </p>
            </div>
            <div className="w-1/2 bg-gray-100 border border-gray-300 rounded-md p-2">
              <p className="text-black text-[10px]">
                For any queries, please contact us at:
                <br /><br />
                Phone: 9030032231
                <br />
                Email: batchugold@gmail.com
                <br /><br />
                Address:
                <br />
                3-4-12/11, Jagadeesh Market,
                <br />
                Mahankali Temple Street, General Bazaar,
                <br />
                Secunderabad, Telangana-03.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-0 right-0 p-4">
        <ReactToPrint
          trigger={() => <button className="bg-blue-500 text-white px-4 py-2 rounded-md">Print Invoice</button>}
          content={() => invoiceRef.current}
        />
      </div>
    </div>
  );
};

export default Invoice;
