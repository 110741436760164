import React from "react";
import { useAuth } from "../../hooks/GlobalProvider";

const SubscriptionForm = () => {
  const { user } = useAuth();

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className=" rounded-lg w-full max-w-lg">
        <p className="text-center text-lg font-semibold">Your Account Details</p>
        <p className="text-center">
          Email Address: <span className="font-medium">{user.emailaddress}</span>
        </p>
        <p className="text-center">
          Phone Number: <span className="font-medium">{user.phonenumber}</span>
        </p>
        <p className="text-center mt-2">
          Please make sure to enter the same details.
        </p>
        <iframe
          src="https://batchugold.com/apis/PhonePe/DigitalGold/Weekly/weeklysubscription.php"
          title="Subscription Form"
          width="100%"
          height="200px"
          frameBorder="0"
          allowFullScreen
          className="mt-4 rounded-lg"
        ></iframe>
      </div>
    </div>
  );
};

export default SubscriptionForm;
