import React from "react";
import { Link } from "react-router-dom";

import footer_gold from "./TGJLogofooter.png";
import footer_csd from "./csdv2Logofooter.svg";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <main className="bg-black">
      {/* Footer */}
      <section className="bg-black pb-10">
        <footer>
          <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-9 lg:px-8">
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
              <div>
              <div className="flex flex-col items-center lg:items-start">
  <Link to="/" className="block">
    <img
      src={footer_gold}
      alt="thegoldjar"
      width={250}
      height={50}
      className="cursor-pointer"
    />
  </Link>
  <p className="max-w-xs mt-4 text-sm text-gray-600 text-center lg:text-left">
    Est. Since 1999, Located in Hyderabad. Buy Gold from 10 rupees.
  </p>
</div>


              
              </div>

              <div className="grid grid-cols-1 gap-8 lg:col-span-2 sm:grid-cols-2 lg:grid-cols-4">
                <div>
                  <p className="font-medium text-white">Company</p>
                  <nav className="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                    <a href="/aboutus">About us</a>
                    <a href="/MarketPlace">Market</a>
                    <a href="/Store">Store</a>
                  </nav>
                </div>
                <div>
                 
                  
                </div>
                <div>
                  <p className="font-medium text-white">Helpful Links</p>
                  <nav className="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                    <a href="/contactus">Contact</a>
                    <a href="/aboutus">FAQs</a>
                    <a href="https://api.whatsapp.com/send/?phone=919519519581&text=Hello%2C+I+need+some+help.&type=phone_number&app_absent=0">
                      Live Chat
                    </a>
                  </nav>
                </div>
                <div>
                  <p className="font-medium text-white">Legal</p>
                  <nav className="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                    <a href="/termsconditions">Terms & Conditions</a>
                    <a href="/privacypolicy">Privacy Policy</a>
                    <a href="/returnpolicy">Refund Policy</a>
                  </nav>
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex flex-col lg:flex-row justify-between items-center">
                <p className="text-xs text-gray-500">
                  &copy; {currentYear} The Gold Jar. All rights reserved.
                </p>
                <p className="text-xs text-gray-500">
                 Batchu Gold
                </p>
            
              </div>
            </div>
          </div>
        </footer>
      </section>
    </main>
  );
};

export default Footer;
