import React, { useEffect, useState } from "react";
import { useAuth } from "../../hooks/GlobalProvider";
import { USER_FETCH_TRANSACTIONS_URL } from "../../hooks/APIHooks";
import { Link } from "react-router-dom";
import { RiArrowGoBackFill, RiStore3Fill } from "react-icons/ri";

import { GiGoldNuggets, GiMeltingMetal,GiAnt,GiGoldMine } from "react-icons/gi"; // Import GiGoldBar

const getIcon = (shortName) => {
  switch (shortName) {
    case "DigitalGold":
      return <GiGoldNuggets className="h-6 w-6" />;
    case "GoldSmith":
      return <GiMeltingMetal className="h-6 w-6" />;
    case "StoreOrders":
      return <GiGoldMine className="h-6 w-6" />;
    case "ReferralStoreOrders":
      return <GiAnt className="h-6 w-6" />;
    default:
      return null; // Default case if needed
  }
};

const TransactionHistory = () => {
  const { user } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState("All");

  const shortNameFilters = [
    "DigitalGold",
    "GoldSmith",
    "StoreOrders",
    "ReferralStoreOrders",
  ];

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(USER_FETCH_TRANSACTIONS_URL);
        const data = await response.json();

        if (data.success) {
          setTransactions(data.transactions);
          setFilteredTransactions(data.transactions); // Show all initially
        } else {
          setError("Failed to fetch transactions");
        }
      } catch (error) {
        setError("Error fetching transactions");
      }
      setLoading(false);
    };

    fetchTransactions();
  }, []);

  // Function to filter transactions based on shortName
  const filterTransactions = (shortName) => {
    if (shortName === "All") {
      setFilteredTransactions(transactions);
    } else {
      setFilteredTransactions(
        transactions.filter(
          (transaction) =>
            transaction.shortName.replace("BAT_", "") === shortName
        )
      );
    }
    setFilter(shortName);
  };

  return (
    <section className="p-2 bg-white min-h-screen py-20">
      {loading ? (
        <div className="flex items-center justify-center h-screen text-red-500">
          Loading...
        </div>
      ) : error ? (
        <div className="flex items-center justify-center h-screen text-red-500">
          {error}
        </div>
      ) : (
        <div className="py-6 mb-6">
          <h2 className="text-[18px] font-bold m-2 text-center uppercase">
            Transaction History
            <p className="text-[10px] font-normal">
              Please make sure to submit your Pan Card details, if your order is
              more than 2Lakh rupees.
            </p>
          </h2>

          {/* Scrollable Filter Buttons */}
          <div className="flex space-x-1 mb-4 overflow-x-auto scrollbar-hide text-[12px]">
            <button
              className={`px-4 py-2 rounded-lg whitespace-nowrap ${
                filter === "All" ? "bg-yellow-500 text-white" : "bg-yellow-500"
              }`}
              onClick={() => filterTransactions("All")}
            >
              All
            </button>
            {shortNameFilters.map((name) => (
              <button
                key={name}
                className={`px-4 py-2 rounded-lg whitespace-nowrap ${
                  filter === name ? "bg-yellow-500 text-white" : "bg-yellow-500"
                }`}
                onClick={() => filterTransactions(name)}
              >
                {name}
              </button>
            ))}
          </div>

          {/* Transaction List */}
          <div className="space-y-2 px-4">
            {filteredTransactions
              .filter((transaction) => transaction.email === user.emailaddress)
              .map((transaction) => (
                <div
                  key={transaction.merchantTransactionId}
                  className="bg-white shadow-xl drop-shadow-md text-black p-4 rounded-lg "
                >
                  <div className="flex justify-between">
                    <div className="flex items-center">
                      {getIcon(transaction.shortName.replace("BAT_", ""))}{" "}
                      {/* Render the icon here */}
                      <h4 className="text-[12px] font-bold ml-2">
                        {transaction.shortName.replace("BAT_", "")}
                      </h4>
                    </div>
                    <p className="text-lg font-bold">₹{transaction.amount}</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="text-[12px]">
                      Date: {transaction.created_at || transaction.order_date}
                    </p>
                   
                      <td
                        className={`text-[10px] font-bold ${ 
                          transaction.status.toLowerCase() === "completed"
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                      {transaction.status}
                      </td>
                  </div>
                  {/* Conditionally render orderlist and goldgrams */}
                  {transaction.orderlist && (
                    <p className="text-[12px]">
                      <strong>Order List: </strong>
                      {transaction.orderlist}
                    </p>
                  )}
                  {transaction.goldgrams && (
                    <p className="text-[12px]">
                      <strong>Gold Grams: </strong>
                      {transaction.goldgrams}
                    </p>
                  )}
                </div>
              ))}
          </div>

          {/* Navigation Links */}
          <div className="flex justify-center fixed bottom-0 w-full p-2 z-10">
            <div className="flex flex-row justify-around w-full max-w-md p-2 bg-yellow-500 mx-2 rounded-full">
              <div className="flex flex-col items-center group">
                <Link
                  to="/user/dashboard"
                  className="flex flex-col items-center px-4 rounded-full text-white"
                >
                  <RiArrowGoBackFill className="h-6 w-6 mb-1 text-white" />
                  <span className="text-[10px]">BACK</span>
                </Link>
              </div>
              <div className="flex flex-col items-center group">
                <Link
                  to="/"
                  className="flex flex-col items-center px-4 rounded-full text-white"
                >
                  <RiStore3Fill className="h-6 w-6 mb-1 text-white" />
                  <span className="text-[10px]">HOME</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default TransactionHistory;
