import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Aboutus,
  GoldCoin_BAT,
  Founder,
  Aboutus_title,
  digitalindia,
  GoldBg,
  india,
  madeinindia,
  msme,
  skillindia,
  startup,
  nsws,
  telangana,
  BISHallmarkBlack,
} from "../../assets/data/Imagedata";
import { FaFacebook, FaInstagram, FaPhone, FaEnvelope } from "react-icons/fa";
import MembersSlider from "../../components/MembersSlider";
import PartnershipSlidee from "../../components/PartnershipSlidee";

const supportimages = [
  { src: telangana, alt: "antscity" },
  { src: startup, alt: "antscity" },
  { src: skillindia, alt: "antscity" },
  { src: msme, alt: "antscity" },
  { src: madeinindia, alt: "antscity" },
  { src: india, alt: "antscity" },
  { src: digitalindia, alt: "antscity" },
  { src: nsws, alt: "nsws" },
];

const GoldJarApp = () => {
  const [peopleData, setpeopleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from the API
    axios
      .get("https://batchugold.com/apis/LiveGoldApi.php")
      .then((response) => {
        const data = response.data;
        if (data && data.length > 0) {
          const item = data.find(
            (item) => item.product_name === "PeopleJoined"
          );
          setpeopleData(item);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Error fetching data");
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!peopleData) return <div>No data found for PeopleJoined.</div>;

  const formatPrice = (price) => Math.round(price);
  return (
    <section className="my-20 overflow-hidden">
      <div
        className="relative py-28 sm:py-40"
        style={{
          backgroundImage: `url(${Aboutus})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <img
          title="Introducing The Gold Jar App"
          src={Aboutus_title}
          alt="Aboutus"
          className="fit w-full mx-auto relative z-10"
        />
        <p className="text-white text-center relative z-10 font-bold" >(Mobile App)</p>
        
      </div>

      <section>
        <div className="p-5 ">
          <div className="container mx-auto py-8 px-4">
            {/* Introduction */}
            <section className="mb-8">
              <h1 className="sm:text-[40px] text-[20px] text-yellow-600 uppercase text-center font-bold mb-4">
                Ladies and gentlemen, esteemed guests, and fellow Indians,
              </h1>
              <p className=" mb-4 text-[14px]">
                <a className="font-bold">I am Batchu Sandeep Gupta,</a> and I am
                proud to introduce <a className="font-bold">The Gold Jar</a>, a
                revolutionary concept that will change the way we think about
                gold.
              </p>
            </section>

            {/* Our Legacy */}
            <section className="mb-8">
              <h2 className="sm:text-[40px] text-yellow-600 text-[20px] uppercase text-center  font-bold mb-4">
                Our Legacy
              </h2>
              <p className="mb-4 text-[14px]">
                As the <a className="font-bold">founder</a> of Batchu Gold,
                established in 1999, we have been dedicated to creating
                exquisite gold jewelry for over two decades.
                <a className="font-bold">Our manufacturing</a> unit is located
                near Sahreen market, Charminar, and our store is in Jagadeesh
                Market, Mahankali Temple Street, General Bazaar, Secunderabad,
                Telangana-03.
              </p>
            </section>

            {/* Introducing The Gold Jar */}
            <section className="mb-8">
              <h2 className="sm:text-[40px] text-[30px] uppercase text-center text-yellow-600 font-bold ">
                Introducing The Gold Jar
              </h2>

              <div className="flex overflow-hidden">
                <div className="flex flex-wrap justify-center mx-auto">
                  <img
                    src={GoldCoin_BAT}
                    alt="GoldCoin_BAT"
                    className="fit w-[50px] h-[50px] mx-2 my-2 relative drop-shadow-md"
                  />
                  <img
                    src={GoldCoin_BAT}
                    alt="GoldCoin_BAT"
                    className="fit w-[100px] h-auto  mx-2 my-2 relative drop-shadow-md"
                  />
                  <img
                    src={GoldCoin_BAT}
                    alt="GoldCoin_BAT"
                    className="fit w-[50px] h-[50px] mx-2 my-2 relative drop-shadow-md"
                  />
                </div>
              </div>

              <h3 className="sm:text-[40px] text-yellow-600 text-[20px] uppercase text-center  font-bold mb-2">
                SAVE, EARN, GROW
              </h3>
              <ul className="list-disc list-inside mb-4 text-[14px]">
                <li>BUY gold coins and nuggets with ease</li>
                <li>EARN passive income through our referral program</li>
                <li>GROW your wealth with our automated savings schemes</li>
              </ul>
            </section>

            {/* Our Mission */}
            <section
              className="mb-8 -m-5 px-5 py-2 rounded-lg drop-shadow-xl"
              style={{
                backgroundImage: `url(${GoldBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <h2 className="sm:text-[40px] text-[30px] uppercase text-center font-bold ">
                Our Mission
              </h2>
              <p className="mb-4 text-[14px] text-center">
                To make gold accessible to everyone, regardless of income or
                social status. We believe gold is a smart investment for anyone
                who wants to secure their future.
              </p>
            </section>

            {/* What Sets Us Apart */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">What Sets Us Apart</h2>
              <ul className="list-disc list-inside mb-4">
                <li>
                  <a className="font-bold">Transparency:</a> We source gold bars
                  from reputed bullion merchants and refine it to 24-karat 99.9
                  purity gold coins and gold nuggets(gold flakes).
                </li>
                <li>
                  <a className="font-bold">Affordability:</a> Invest in gold
                  from just Rs 10!
                </li>
                <li>
                  <a className="font-bold">Convenience:</a> Doorstep delivery
                  and automated savings schemes.
                </li>
                <li>
                  <a className="font-bold">Passive Income:</a> Earn commission
                  through our referral program.
                </li>
                <li>
                  <a className="font-bold">Partnerships:</a> Exchange gold coins
                  for 22-karat BIS hallmarked jewelry.
                </li>
                <li>
                  <a className="font-bold">Pan India Concept:</a> Access The
                  Gold Jar from anywhere in the country.
                </li>
                <li>Secure and Transparent E-commerce Platform</li>
                <li>Fast and Efficient Customer Service</li>
                <li>
                  Support for Skill India, Make in India, Digital India,
                  Aatmanirbhar, Swachh Bharat Abhiyaan, and Incredible India
                </li>
              </ul>
            </section>

            {/* Key Features */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">Key Features</h2>
              <ul className="list-disc list-inside mb-4">
                <li>Invest in gold from just Rs 10</li>
                <li>Earn passive income through referrals</li>
                <li>Automated savings schemes</li>
                <li>Doorstep delivery</li>
                <li>Exchange gold coins for jewelry without extra charges</li>
                <li>Accessible from anywhere in India</li>
              </ul>
            </section>

            {/* We All Have Been Seeing From Generations */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">
                We All Have Been Seeing From Generations
              </h2>
              <p className="mb-4">
                That the lower middle class, middle class people have a thought
                that gold is out of their reach, and we have also seen a
                disturbing trend in the industry - jewelers charging exorbitant
                value-added charges to customers.
              </p>
            </section>

            {/* But We at Batchu Gold Believe */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">
                But We at Batchu Gold Believe
              </h2>
              <p className="mb-4">
                That gold is a smart investment for anyone who wants to secure
                their future, regardless of their income or social status. And
                that's why we created{" "}
                <a className="font-bold">"The Gold Jar".</a>
              </p>
            </section>

            {/* You Can Start Buying Gold Nuggets (Gold flakes) */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">
                You Can Start Buying Gold Nuggets (Gold flakes)
              </h2>
              <p className="mb-4">
                From just 10 rupees, making it possible for anyone to invest in
                gold and can also earn with our referral program. We deliver
                gold coins to your doorstep, and our automated daily, weekly,
                and monthly saving schemes make it easy to accumulate gold coins
                over time.
              </p>
            </section>

            {/* But What About Passive Income? */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">
                But What About Passive Income?
              </h2>
              <p className="mb-4">
                We've got you covered. Our referral program offers a commission
                in the form of "ants" - redeemable at our gold store. Share your
                referral link with friends and family, and earn ants for every
                successful referral and purchase. It's a simple way to earn
                extra gold, without any extra effort.
              </p>
            </section>

            <PartnershipSlidee />

            {/* But That's Not All */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">But That's Not All</h2>
              <p className="mb-4">
                We have partnered with other gold jewelers to offer you 22-karat
                91.6 BIS hallmarked jewelry in exchange for our 24-karat 99.9
                pure gold coins, without any extra value-added charges. ( taxes
                applicable)
              </p>
            </section>

            {/* Our Pan India Concept */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">Our Pan India Concept</h2>
              <p className="mb-4">
                Means that you can access The Gold Jar from anywhere in the
                country. At Batchu Gold and The Gold Jar, we are committed to
                empowering the lower middle class, middle class, and upper
                middle class to take control of their financial future.
              </p>
            </section>

            {/* Our E-commerce Website and Mobile Application */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">
                Our E-commerce Website and Mobile Application
              </h2>
              <p className="mb-4">
                Are designed to make gold investing easy, secure, and
                transparent. Our customer service is fast and efficient, and we
                prioritize customer satisfaction above all else.
              </p>
            </section>

            {/* We Understand That Investing in Gold Can Be Daunting */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">
                We Understand That Investing in Gold Can Be Daunting
              </h2>
              <p className="mb-4">
                Especially for those who are new to it. That's why we have
                created a safe and secure platform for you to invest in gold in
                smaller amounts. Our gold coins are tamper-proof, and our
                delivery partners ensure that your gold reaches your doorstep
                safely.
              </p>
            </section>

            {/* But What About Those Who Want to Invest in Smaller Amounts? */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">
                But What About Those Who Want to Invest in Smaller Amounts?
              </h2>
              <p className="mb-4">
                We have got you covered. Our gold nuggets ( gold flakes) are
                available for investments below Rs 2000, and you can collect
                them from our store. It's a great way to start investing in
                gold, even with a limited budget.
              </p>
            </section>

            <img
              src={BISHallmarkBlack}
              alt="GoldCoin_BAT"
              className="fit w-[200px] mx-auto relative mb-4 drop-shadow-md"
            />

            {/* Our Surprise Gifts and Referral Commissions */}
            <section className="mb-4">
              <h2 className="text-2xl font-bold mb-4">
                Our Surprise Gifts and Referral Commissions
              </h2>
              <p className="mb-4">
                Are just the cherry on top. We want to make investing in gold a
                rewarding experience for you, and we are committed to doing just
                that.
              </p>
            </section>

            {/* The Three Advices Which We Give to Everyone Are */}
            <section>
              <h2 className="text-2xl font-bold mb-4">
                The Three Advices Which We Give to Everyone Are
              </h2>
              <ol className="list-decimal list-inside">
                <li>Buy gold and save it.</li>
                <li>Refer and earn a passive income.</li>
                <li>Grow your wealth with The Gold Jar.</li>
              </ol>
            </section>
          </div>
        </div>
      </section>

      <MembersSlider />

      <section>
        <h3 className="text-center font-black text-[25px]">In Support with</h3>
        <div className="contain flex gap-2 flex-wrap bg-white p-5">
          {supportimages.map((image, index) => (
            <img
              className="w-auto h-[70px] mx-auto"
              key={index}
              src={image.src}
              alt={image.alt}
            />
          ))}
        </div>
      </section>

      <div className="mx-auto">
        <div className="flex flex-wrap justify-center">
          {/* First Row */}
          <div className="flex justify-center items-center w-1/2 p-4">
            <section className="text-center">
              <img
                src={Founder}
                alt="Gold"
                className=" w-auto h-[220px] mx-auto m-4 rounded-lg"
              />
            </section>
          </div>
          <div className="text-center my-auto  justify-center items-center w-1/2 p-4">
            <p className="text-sm font-black tracking-tighter">FOUNDER</p>
            <p className="text-xl">Batchu Sandeep Gupta</p>
            <div className="flex justify-center my-4 space-x-4">
              <a
                href="tel:+919519519581"
                className="text-2xl text-black hover:text-green-800"
              >
                <FaPhone />
              </a>
              <a
                href="mailto:mail@batchugold.com"
                className="text-2xl text-black hover:text-red-800"
              >
                <FaEnvelope />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61560365941913&mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
                className="text-2xl text-black hover:text-blue-800"
              >
                <FaFacebook />
              </a>
              <a
                href="https://www.instagram.com/thegoldjar999?igsh=MWRiNnA3eTdxZXh1OA%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
                className="text-2xl text-black hover:text-pink-700"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          textDecoration: "none",
          overflow: "hidden",
          maxWidth: "100%",
          width: "auto",
          height: 500,
        }}
      >
        <h2 className="text-center font-black text-[30px] p-5">Find us at</h2>
        <div
          id="display-google-map"
          style={{ height: "100%", width: "100%", maxWidth: "100%" }}
        >
          <iframe
            style={{ height: "100%", width: "100%", border: 0 }}
            frameBorder={0}
            src="https://www.google.com/maps/embed/v1/place?q=Batchu+Gold+,3-12,+jagadeesh+market,,+4,+Mahankali+St,+General+Bazaar,+Kalasiguda,+Secunderabad,+Telangana+500003&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          />
        </div>
        <a
          className="google-map-html"
          href="https://www.bootstrapskins.com/themes"
          id="authmaps-data"
        ></a>
        <style
          dangerouslySetInnerHTML={{
            __html:
              "#display-google-map img{max-height:none;max-width:none!important;background:none!important;}",
          }}
        />
      </div>

      <div class="relative flex flex-col items-center justify-center overflow-hidden bg-yellow-500 p-8 sm:p-12">
        <div class="w-full max-w-4xl rounded-md border-2 border-yellow-100 bg-yellow-600 p-14">
          <div class="flex flex-col items-center text-white">
            <span class="-rotate-1 rounded-lg bg-yellow-100 py-px px-2 text-sm text-yellow-800">
              {formatPrice(peopleData.price1)}+ People watching this site
            </span>

            <h3 class="mt-2 max-w-2xl text-center text-2xl  font-bold leading-tight sm:text-3xl md:text-4xl md:leading-tight">
              Thank You!
            </h3>
            <p className="text-center">
              Start Investing in Gold. Start Securing Your Future. Start
              Building a Brighter Tomorrow for Yourself and Your Loved Ones.
            </p>
          </div>
        </div>
      </div>

      {/* FAQ */}
      <section>
        <div>
          <section class="text-black pt-10">
            <div class="container px-5 mx-auto">
              <div class="text-center mb-10">
                <h2 class="sm:text-3xl text-2xl text-center font-bold text-black mb-4">
                  Frequently Asked{" "}
                  <span className="text-yellow-500">Questions</span>
                </h2>
                <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-black">
                  Find answers to the most common questions about The Gold Jar.
                </p>
              </div>
              <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                <div class="w-full lg:w-1/2 px-4 py-2">
                  <details class="mb-4">
                    <summary class="font-semibold bg-yellow-500 rounded-md py-2 px-4">
                      Why The Gold Jar is the best?
                    </summary>
                    <span className="text-black">
                      There are multiple ways to invest in virtual gold, such as
                      Gold ETFs, sovereign bonds, gold mutual funds, and digital
                      gold where user can only take benefit of the prices. The
                      Gold Jar app is the one that suits users' requirements. It
                      is the only one and first physical gold app that offers
                      the user to buy 24 karat 99.9% gold starting from Rs 10
                      and user can collect it from their store or wait and get
                      the product delivered at your location after it reaches
                      the coin target i.e 0.200 grams.
                    </span>
                  </details>
                  <details class="mb-4">
                    <summary class="font-semibold bg-yellow-500 rounded-md py-2 px-4">
                      Can you delivery Gold Nuggets / Gold Flakes?
                    </summary>
                    <span className="text-black">
                      No, We dont deliver gold nuggets. You can reachout our
                      store (BatchuGold Store) and get your Gold Nuggets.
                    </span>
                  </details>
                  <details class="mb-4">
                    <summary class="font-semibold bg-yellow-500 rounded-md py-2 px-4">
                      Is The Gold Jar safe to buy?
                    </summary>
                    <span className="text-black">
                      Yes, comparing to digital gold, The Gold Jar is very safe
                      to buy. The gold comes with 99.9% purity, and hence user
                      need not worry about the genuineness and purity. Batchu
                      Gold and The Gold Jar do not store more than 1 gram gold
                      in their safe vault. For example, If the user stores gold
                      nuggets in their wallet account and don't collect even
                      after reaching the maximum target (1:000 gram), Batchu
                      Gold staff will call to the user's registered mobile
                      number and communicate with them and see that the product
                      is sent to their delivery address. Till then, their gold
                      is stored in a secured vault.
                    </span>
                  </details>
                  <details class="mb-4">
                    <summary class="font-semibold bg-yellow-500 rounded-md py-2 px-4">
                      Is The Gold Jar profitable?
                    </summary>
                    <span className="text-black">
                      The Gold Jar is backed by physical gold and not digital
                      gold. Hence it gives good return to the users. Since gold
                      is one commodity that grows in the long term, investing in
                      The Gold Jar is profitable.
                    </span>
                  </details>
                  <details class="mb-4">
                    <summary class="font-semibold bg-yellow-500 rounded-md py-2 px-4">
                      Is The Gold Jar taxable?
                    </summary>
                    <span className="text-black">
                      Yes. 3% G.S.T are applicable on every purchase done by the
                      user. Here, User doesn't have an option of long-term
                      savings with The Gold Jar, so the user can enjoy the
                      capital gains. But in digital gold apps, capital gains on
                      gold are taxable. If user sell their digital gold in the
                      short term (before three years), the capital gains are
                      taxable at the income tax slab rate. Suppose user sell the
                      digital gold in the long term (after three years), the
                      capital gains are taxable at 20%.
                    </span>
                  </details>
                  <details class="mb-4">
                    <summary class="font-semibold bg-yellow-500 rounded-md py-2 px-4">
                      Is buying gold in The Gold Jar a good investment?
                    </summary>
                    <span className="text-black">
                      Yes. Gold acts as a perfect hedge against inflation. It is
                      the only commodity that can help beat inflation. Moreover,
                      it helps prevent the downside risk of a portfolio. Hence
                      having gold in users portfolio will help boost the overall
                      portfolio returns. Here, in The Gold Jar, user can buy
                      gold nuggets in small quantities.
                    </span>
                  </details>
                  <details class="mb-4">
                    <summary class="font-semibold bg-yellow-500 rounded-md py-2 px-4">
                      Is it worth buying gold in The Gold Jar?
                    </summary>
                    <span className="text-black">
                      Yes, The Gold Jar is an excellent investment app if user
                      is looking to invest in gold. Firstly, user has not to
                      worry about the purity as they get 24 karat 99.9% purity
                      gold. Second, The Gold Jar doesn't store users gold for a
                      long term in the secured vault and they see that the
                      product is delivered to the user. Third, The Gold Jar is
                      offering many benefits to the user. User can earn a
                      passive income through this app. User also gets surprise
                      gifts for every purchase. Fourth, user can purchase 22
                      karat 91.6% B.I.S hallmarked jewellery at their store or
                      their partner store in exchange of The Gold Jar 24 karat
                      99.9% gold coins without paying any making or wastage or
                      value-added charges (for specific categorized designs).
                      Fifth, in saving schemes, after doing the payment to The
                      Gold Jar, the user gets the gold delivered to their
                      address and the user has to decide what to do with the
                      gold. Sixth, with The Gold Jar, user can buy gold and save
                      it, earn ants through referrals, grow with The Gold Jar.
                      In short: SAVE, EARN AND GROW. Overall, gold is a perfect
                      diversification.
                    </span>
                  </details>
                  <details class="mb-4">
                    <summary class="font-semibold bg-yellow-500 rounded-md py-2 px-4">
                      A Revolutionary Physical Gold Investment Platform
                    </summary>
                    <span className="text-black">
                      <p className="text-[12px] mb-4">
                        The Gold Jar app is a groundbreaking and user-friendly
                        platform that caters to each and every user's
                        requirements, providing a seamless and secure way to
                        invest in physical gold.
                      </p>

                      <ul className="list-disc list-inside text-[12px]">
                        <li>
                          Investment in 24K 99.9% physical gold starting from a
                          minimal Rs 10, making gold investment accessible to
                          all.
                        </li>
                        <li>
                          Unparalleled transparency by clearly mentioning all
                          charges before purchase, ensuring users know exactly
                          what they're paying for.
                        </li>
                        <li>
                          Minimum margins, ensuring users receive the maximum
                          value for their investment.
                        </li>
                        <li>
                          Referral bonuses in the form of 'ants' to encourage
                          passive income and reward users for spreading the
                          word.
                        </li>
                        <li>
                          Seamless conversion of accumulated gold into jewelry
                          without added value-added charges or making charges
                          (for categorized collection); 3% GST applicable,
                          ensuring users get the best value.
                        </li>
                      </ul>
                    </span>
                  </details>
                  <details class="mb-4">
                    <summary class="font-semibold bg-yellow-500 rounded-md py-2 px-4">
                      is it Safe and secure storage?
                    </summary>
                    <span className="text-black">
                      <ul className="list-disc list-inside ">
                        <li>
                          Safe and secure storage in a state-of-the-art vault,
                          with no storage of more than 1 gram gold, ensuring
                          users' gold is protected.
                        </li>
                        <li>
                          Personalized gold saving schemes, allowing users to
                          receive gold delivery at their registered address
                          without waiting for the scheme to mature, providing
                          flexibility and control.
                        </li>
                        <li>
                          Flexibility to exchange 24K 99.9% gold into jewelry at
                          our partner store, with no conditions, allowing users
                          to utilize their gold as they see fit.
                        </li>
                        <li>
                          Investment backed by physical gold, ensuring long-term
                          growth and returns, providing a hedge against
                          inflation and market volatility.
                        </li>
                        <li>999 24 Karat Gold.</li>
                      </ul>

                      <p className=" text-[12px}">
                        seamless conversion of 999 24 karat gold into 91.6 b.i.s
                        hallmarked jewellery without paying any extra value
                        added charges or making charges ( for categorized
                        collection); 3% GST applicable, ensuring users get the
                        best value.
                        <br />
                        Exchange our 24 karat 999 coins at our partner stores
                        and take 22 karat 916 b.i.s hallmarked jewellery.
                        <br />
                        Exchange Offer!Visit our partner stores and exchange
                        your 24 Karat 999 gold coins for 22 Karat 916 BIS
                        hallmarked jewellery. Enjoy a seamless exchange
                        experience on categorized collection and upgrade your
                        gold collection.(3% G.S.T applicable)
                      </p>
                    </span>
                  </details>
                  

                  <details class="mb-4">
                    <summary class="font-semibold bg-yellow-500 rounded-md py-2 px-4">
                    How does The Gold Jar app differ from other digital apps? 
                    </summary>
                    <span className="text-black">
                      
                      <p className=" text-[12px}">
                      The Gold Jar differentiates itself from other digital apps that only facilitate money transactions in the name of gold or savings programs, where users only receive monetary value in exchange for their investments. In contrast, The Gold Jar specializes in authentic gold transactions, allowing users to buy and store physical gold. We prioritize transparency and authenticity, ensuring that our users' investments are secure and tangible.
                      </p>
                    </span>
                  </details>

                </div>
                <div class="w-full lg:w-1/2 px-4 py-2">
                  <details class="mb-4">
                    <summary class="font-semibold bg-orange-500 rounded-md py-2 px-4">
                      When to buy gold in The Gold Jar?
                    </summary>
                    <span className="text-black">
                      Even if the price of gold peaks during certain months, the
                      best time to buy gold is from January to December. Gold is
                      a luck sign, user can buy it any time.
                    </span>
                  </details>
                  <details class="mb-4">
                    <summary class="font-semibold bg-orange-500 rounded-md py-2 px-4">
                      Why The Gold Jar app is not having sell option?
                    </summary>
                    <span className="text-black">
                      The Gold Jar is not a trade entity and doesn't encourage
                      sell option in the app. According to the Indian culture,
                      sentiment and psychology, The Gold Jar and Batchu Gold
                      considers that gold is an auspicious product and everyone
                      in India are obsessed with it. Everyone wishes to include
                      gold in festivals and considered it as the most auspicious
                      purchase. The Gold Jar app and the Batchu Gold has a
                      mission to create an eco-system of saving gold which is
                      accessible to all, helping billion people in India to grow
                      their wealth by giving ways to public to save easily and
                      earn smartly. Batchu Gold vision is to see every person in
                      the country making wealth creation easy and accessible.
                      That is the reason, there is no sell option.
                    </span>
                  </details>
                  <details class="mb-4">
                    <summary class="font-semibold bg-orange-500 rounded-md py-2 px-4">
                      How can a user convert their gold purchase into cash in
                      The Gold Jar?
                    </summary>
                    <span className="text-black">
                      Gold is a go-to investment if having excess money.
                      According to The Gold Jar and Batchu Gold, Gold is also
                      called as a store of wealth. If the user still wants to
                      convert gold into cash for their personal reasons, user
                      can contact on given customer care number or send a mail
                      to registered email ID. The Gold Jar staff will find a
                      solution and resolve it.
                    </span>
                  </details>
                  <details class="mb-4">
                    <summary class="font-semibold bg-orange-500 rounded-md py-2 px-4">
                      Does The Gold Jar app gold price increase or decrease?
                    </summary>
                    <span className="text-black">
                      Yes, the price of gold is the same as bullion gold. Hence
                      the price of gold fluctuates based on demand and supply.
                    </span>
                  </details>
                  <details class="mb-4">
                    <summary class="font-semibold bg-orange-500 rounded-md py-2 px-4">
                      Can NRI buy gold in The Gold Jar app in India?
                    </summary>
                    <span className="text-black">
                      Yes. But the NRI as a user has to bear the payment gateway
                      charges, transportation charges that are expensive.
                      Instead, the user NRI can take help of their family
                      members, friends or relatives staying in India and
                      purchase behalf of them.
                    </span>
                  </details>
                  <details class="mb-4">
                    <summary class="font-semibold bg-orange-500 rounded-md py-2 px-4">
                      Can user withdraw gold from The Gold Jar?
                    </summary>
                    <span className="text-black">
                      Yes, user can withdraw their physical gold whenever they
                      want. If the gold is in form of nuggets (below 0:200
                      grams), the user has to come to Batchu Gold store and
                      collect their gold. Some digital gold apps offer users to
                      convert their digital gold into money whenever they want.
                      However, they charge high for withdrawal. The Gold Jar and
                      Batchu Gold doesn't store users' gold for long term. Hence
                      the user has to decide what they want to do with the gold.
                    </span>
                  </details>
                  <details class="mb-4">
                    <summary class="font-semibold bg-orange-500 rounded-md py-2 px-4">
                      Does The Gold Jar offer long-term saving?
                    </summary>
                    <span className="text-black">
                      The Gold Jar offers users long term savings of 1:000 gram
                      with easy monthly installments in gold.
                    </span>
                  </details>

                  <details class="mb-4">
                    <summary class="font-semibold bg-orange-500 rounded-md py-2 px-4">
                      Things to Consider Before Buying Physical Gold
                    </summary>
                    <span className="text-black">
                      Making Charges and Value-Added Charges
                      <ul className="list-disc list-inside">
                        <li>
                          Physical gold jewelry comes with additional charges:
                        </li>
                        <li>Making charges (12-25% of the gold value)</li>
                        <li>Value-added charges</li>
                        <li>3% GST (Goods and Services Tax) extra</li>
                      </ul>
                    </span>
                  </details>

                  <details class="mb-4">
                    <summary class="font-semibold bg-orange-500 rounded-md py-2 px-4">
                      Investment Purpose
                    </summary>
                    <span className="text-black">
                      <ul className="list-disc list-inside">
                        <p>
                          If you're buying gold for investment purposes,
                          consider:
                        </p>
                        <ul className="list-disc list-inside">
                          <li>
                            Coins, biscuits, or digital forms of gold (lower
                            charges)
                          </li>
                          <li>Avoid jewelry with high making charges</li>
                        </ul>
                      </ul>
                    </span>
                  </details>
                  <details class="mb-4">
                    <summary class="font-semibold bg-orange-500 rounded-md py-2 px-4">
                      Taxation on Capital Gains
                    </summary>
                    <span className="text-black">
                      Making Charges and Value-Added Charges
                      <ul className="list-disc list-inside">
                        <p>
                          Gold is considered an asset, and capital gains are
                          subject to tax, depending on:
                        </p>
                        <ul className="list-disc list-inside">
                          <li>Type of gold investment</li>
                          <li>Holding period</li>
                        </ul>
                        <p className="font-semibold">Tax rates:</p>
                        <ul className="list-disc list-inside">
                          <li>
                            Short-term capital gains: Investor's tax slab rate
                          </li>
                          <li>
                            Long-term capital gains: 20% with indexation (for
                            physical and digital gold)
                          </li>
                          <li>
                            Gold funds and gold ETFs with equity exposure 35%:
                            Taxed at investor's tax slab rate (irrespective of
                            holding period)
                          </li>
                        </ul>
                      </ul>
                    </span>
                  </details>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      <div className=" bg-yellow-500">
        <div className="max-w-2xl mx-auto text-white py-10">
          <div className="text-center">
            <h3 className="text-3xl mb-3">
              {" "}
              Join the Journey to a Brighter Tomorrow!
            </h3>
            <p> Stay fit. All day, every day. </p>
            <div className="flex justify-center my-10">
              <div className="flex items-center border w-auto rounded-lg px-4 py-2 mx-2">
              <img 
      src="https://cdn-icons-png.flaticon.com/512/888/888857.png"
      className="w-7 md:w-8"
      alt="Icon"
    />
                <div className="text-left ml-3">
                  <p className="text-xs text-gray-200">Download on </p>
                  <p className="text-sm md:text-base"> Google Play Store </p>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </section>
  );
};

export default GoldJarApp;
