import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom"; // Import useNavigate

const VerifyUPI = () => {
  const [upi, setUPI] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(""); // New state for phone
  const [amount, setAmount] = useState(""); // New state for amount
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Get the subscriptionId, phone, email, and amount from the URL parameters
  const [searchParams] = useSearchParams();
  const insertId = searchParams.get("insert_id");
  const subscriptionId = searchParams.get("subscriptionId");
  const phonenumber = searchParams.get("phonenumber");
  const emailaddress = searchParams.get("emailaddress");
  const amountParam = searchParams.get("amount"); // Get amount from URL
  

  // Set initial values for phone, email, and amount from URL parameters
  useEffect(() => {
    if (phonenumber) {
      setPhone(phonenumber);
    }
    if (emailaddress) {
      setEmail(emailaddress);
    }
    if (amountParam) {
      setAmount(amountParam); // Set amount from URL
    }
  }, [phonenumber, emailaddress, amountParam]);

  const navigate = useNavigate(); // Initialize the navigate function

  const handleVerifyUPI = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setMessage("");

    // Payload to send to the backend
    const payload = {
      id: insertId,
      subscriptionId:subscriptionId,
      upi,
      email,
      phone, // Include phone in the payload
      verify: true,
    };

    try {
      // Sending the POST request to the backend
      const response = await fetch(
        "https://batchugold.com/apis/PhonePe/DigitalGold/Daily/verifyupi.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload), // Send payload as JSON
        }
      );

      // Parse the JSON response from the server
      const data = await response.json();

      if (data.status === "success") {
        // If UPI verification is successful, show success message
        setMessage(`UPI Verified! Proceed to: ${data.next_step}`);

        // Redirect to /MarketPlace/submit-auth-req with amount and upi as query parameters
        navigate(
          `/MarketPlace/submit-auth-req?insert_id=${insertId}&amount=${amount}&upi=${upi}&subscriptionId=${subscriptionId}`
        );
      } else {
        // If there's an error, display the error message
        setError(data.message || "Something went wrong. Please try again.");
      }
    } catch (err) {
      // Handle any network or unexpected errors
      setError("An error occurred while verifying UPI. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleVerifyUPI}
      className="max-w-md mx-auto p-4 bg-white rounded-lg shadow-lg mt-20"
    >
      <h4 className="text-2xl font-bold mb-4 text-center">
        Step 2 - Verify UPI ID to Autopay
      </h4>


      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          ID:
        </label>
        <input
          type="text"
          value={insertId}
          readOnly
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-200"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Subscribeid:
        </label>
        <input
          type="text"
          value={subscriptionId}
          readOnly
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-200"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          UPI ID:
        </label>
        <input
          type="text"
          value={upi}
          onChange={(e) => setUPI(e.target.value)}
          placeholder="Enter UPI ID"
          required
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      
     
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Amount:
        </label>
        <input
          type="text"
          value={amount}
          readOnly
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-200"
        />
      </div>

      <button
        type="submit"
        disabled={loading}
        className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md shadow hover:bg-blue-700 transition duration-200"
      >
        {loading ? "Verifying..." : "Verify UPI"}
      </button>

      {message && (
        <div className="mt-4 p-4 border border-green-500 bg-green-100 rounded-md">
          <p style={{ color: "green" }}>{message}</p>
        </div>
      )}
      {error && (
        <div className="mt-4 p-4 border border-red-500 bg-red-100 rounded-md">
          <p style={{ color: "red" }}>{error}</p>
        </div>
      )}
    </form>
  );
};

export default VerifyUPI;
