import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/GlobalProvider';
import { GOLD_LIVE_PRICE } from '../../hooks/APIHooks';


const QuickBuy = () => {
  const { user } = useAuth();
  const [amount, setAmount] = useState('');
  const [gold, setGold] = useState(0);
  const [goldPricePerGram, setGoldPricePerGram] = useState(0);
  const [formattedGold, setFormattedGold] = useState('');
  const [error, setError] = useState(''); // For error message
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // For button state

  const [formData, setFormData] = useState({
    merchantTransactionId: '',
    merchantUserId: user.emailaddress,
    amount: '',
    merchantOrderId: user.name,
    mobileNumber: user.phonenumber,
    message: 'Order For Digital Gold - Quick buy',
    email: user.emailaddress,
    shortName: 'BAT_DigitalGold',
    goldgrams: '',
  });

  useEffect(() => {
    const fetchGoldPrice = async () => {
      try {
        const response = await fetch(GOLD_LIVE_PRICE);
        const data = await response.json();
        const goldPrice = data.find(item => item.product_name === 'Gold');
        if (goldPrice) {
          setGoldPricePerGram(goldPrice.price);
        } else {
          console.error('Gold price not found in API response');
        }
      } catch (error) {
        console.error('Error fetching gold price:', error);
      }
    };

    fetchGoldPrice();
  }, []);

  useEffect(() => {
    const calculateGoldAfterGST = () => {
      const goldAfterGST = gold * (1 - 0.35); // Assuming 35% GST + other charges
      const formattedGold = goldAfterGST.toFixed(8);
      setFormattedGold(formattedGold);
    };

    calculateGoldAfterGST();
  }, [gold]);

  const handleAmountChange = (value) => {
    setAmount(value);

    if (value < 10 || value > 2000) {
      setError('Please enter an amount between ₹10 and ₹2000');
      setIsButtonDisabled(true);
    } else {
      setError('');
      setIsButtonDisabled(false);

      const calculatedGold = value / goldPricePerGram;
      setGold(calculatedGold);

      const formattedGoldAfterGST = (calculatedGold * (1 - 0.35)).toFixed(8);
      setFormattedGold(formattedGoldAfterGST);

      setFormData(prevFormData => ({
        ...prevFormData,
        amount: value,
        merchantTransactionId: 'MTID' + Date.now(),
        merchantOrderId: 'MOID' + Date.now(),
        goldgrams: `${formattedGoldAfterGST}`,
      }));
    }
  };

  const handlePayment = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://batchugold.com/apis/PhonePe/DigitalGold/QuickBuy/PhonePe.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      if (responseData.error) {
        console.error('Error:', responseData.error);
      } else {
        window.location.href = responseData.iframeUrl;
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="p-5 flex flex-col items-center justify-center">
      <div className="p-8 w-full max-w-md">
        <div className="bg-gradient-to-r from-[#facc15] to-[#ca8a04] p-8 rounded shadow-md w-full max-w-md">
          <h1 className="text-2xl font-bold mb-4 text-center text-white">Quick Gold Buy</h1>
          <label className="block mb-2 text-white font-bold">Enter Amount (INR):</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => handleAmountChange(Number(e.target.value))}
            className="w-full px-2 py-1 mb-4 border rounded"
          />
          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
          <p className="text-center mt-4">
            Rs. {amount}
          </p>
          <p className="mb-4 text-[12px]">
            Receive: {formattedGold} grams
          </p>
          <button
            onClick={handlePayment}
            className={`w-full bg-yellow-900 text-white py-2 rounded hover:bg-yellow-700 ${isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isButtonDisabled}
          >
            Buy Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuickBuy;
