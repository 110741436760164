import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../hooks/GlobalProvider";
import { USER_CURRENCY_ANTS } from "../../hooks/APIHooks";
import { Link, useLocation } from "react-router-dom";
import { ReferralCover, Ant, Goldraw } from "../../assets/data/Imagedata";

const UserCurrency = () => {
  const { user } = useAuth();
  const [currency, setCurrency] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  const fetchCurrency = async (email) => {
    try {
      const response = await axios.get(
        `${USER_CURRENCY_ANTS}?emailaddress=${email}`
      );
      setCurrency(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user || !user.emailaddress) return;
    setLoading(true);
    fetchCurrency(user.emailaddress);
  }, [user, location]);

  return (
    <section
      className="py-20 sm:h-screen"
      style={{
        backgroundImage: `url("${ReferralCover}")`,
        backgroundSize: "contain",
        backgroundPosition: "top",
      }}
    >
      <div className="grid grid-cols-2 gap-4 p-4">
        {/* Ant Points Section */}
        <div className="relative h-full items-center justify-end">
          <div className="relative z-10 text-right">
            {currency ? (
              <div className="text-center p-1">
                <img
                  src={Ant}
                  alt="Ant Points"
                  className="w-[100px] h-auto justify-center mx-auto"
                />
                <p className="text-white text-[20px] font-bold">
                  {currency.ants}
                </p>
                <p className="text-[12px] text-white font-bold">Ant Points</p>
                <div className="text-left">
                  <p className="text-[12px] text-white text-center pt-4">
                    What are Ant Points?
                  </p>
                  <p className="text-[12px] text-white text-justify mb-[75px] sm:mb-[10px]">
                    "Ants" are your referral points, earned each time you make a
                    purchase or refer someone who buys a product using your
                    email address as the referral. These referral points, or
                    "Ants," can be accumulated and converted into gold coins
                    from the Store or Referral Store.
                  </p>

                  <section className="text-white py-2">
                    <h3 className="text-center text-[18px] font-black ">
                      1 ANT = 1₹
                    </h3>

                    <Link to="/Store/referralstore">
                      <button className="drop-shadow-xl bg-yellow-500 text-white py-2 w-full">
                        Convert Ant Points
                      </button>
                    </Link>
                  </section>

                  {/* FAQ */}
                  <section>
                    <div>
                      <section className="text-white">
                        <h3 className="text-center font-black">FAQ</h3>
                        <div className="flex flex-wrap">
                          <div className="w-full px-2 py-2 text-[12px] space-y-1">
                            <details className="bg-black/60">
                              <summary className="bg-yellow-500 rounded-md py-2 px-2">
                                Advantage of Ant Points?
                              </summary>
                              <span className="text-white text-justify">
                                Ants work similarly as Referral Points. Each
                                Point is equivalent to 1 INR. You can earn
                                unlimited points by referring as many people as
                                you can.
                              </span>
                            </details>

                            <details className="bg-black/60">
                              <summary className="bg-yellow-500 rounded-md py-2 px-2">
                                How often can I earn Ant Points?
                              </summary>
                              <span className="text-white text-justify">
                                You can earn unlimited points from any purchase
                                you make or request someone to make, but make
                                sure to add your email address in the referral
                                section.
                              </span>
                            </details>

                            <details className="bg-black/60">
                              <summary className="bg-yellow-500 rounded-md py-2 px-2">
                                Where can I convert them?
                              </summary>
                              <span className="text-white text-justify">
                                You can convert them from the "Referral Store"
                                on the Store Page.
                              </span>
                            </details>
                          </div>
                        </div>
                      </section>
                    </div>
                  </section>
                </div>
              </div>
            ) : (
              <p className="text-[10px] text-white">0 Earned</p>
            )}
          </div>
        </div>

        {/* Gold Nuggets Section */}
        <div className="relative h-full items-center justify-end">
          <div className="relative z-10 text-right">
            {currency ? (
              <div className="text-center p-1">
                <img
                  src={Goldraw}
                  alt="Gold Nuggets"
                  className="w-[100px] h-auto justify-center mx-auto"
                />
                <p className="text-white text-[20px] font-bold">
                  {parseFloat(currency.gold_grams).toFixed(3)}
                </p>
                <p className="text-[12px] text-white font-bold">
                  Gold Nuggets (Gold Flakes)
                </p>
                <div className="text-left">
                  <p className="text-[12px] text-white text-justify">
                    "Introducing Gold Nuggets (Gold Flakes) - small pieces cut
                    from 24K 999 gold bars! Convert your gold nuggets into 24K
                    gold coins without any additional fees at our Goldsmith
                    store page."
                  </p>

                  <p className="text-[12px] text-white text-justify pt-4">
                    Instead of gold coins, you may now choose to receive
                    24-karat, 999 purity gold nuggets (gold flakes) from our
                    Batchu Gold store. Visit us to collect your gold and enjoy
                    an exclusive 10% cashback offer.
                  </p>

                  <section className="text-white py-2">
                    <h3 className="text-center text-[18px] font-black">
                      Gold Nuggets (Gold Flakes)
                    </h3>

                    <Link to="/Store/goldsmelter">
                      <button className="drop-shadow-xl bg-yellow-500 text-white py-2 w-full">
                        Convert Gold Nuggets
                      </button>
                    </Link>
                  </section>

                  {/* FAQ */}
                  <section>
                    <div>
                      <section className="text-white">
                        <h3 className="text-center font-black">FAQ</h3>
                        <div className="flex flex-wrap">
                          <div className="w-full px-2 py-2 text-[12px] space-y-1">
                            <details className="bg-black/60">
                              <summary className="bg-yellow-500 rounded-md py-2 px-2">
                                Advantage of Gold Nuggets?
                              </summary>
                              <span className="text-white text-justify">
                                When purchasing Gold Nuggets, we calculate the
                                total making and handling charges. So, while
                                converting the Gold Nuggets into coins, you will
                                not be charged additionally.
                              </span>
                            </details>

                            <details className="bg-black/60">
                              <summary className="bg-yellow-500 rounded-md py-2 px-2">
                                How often can I buy Gold Nuggets?
                              </summary>
                              <span className="text-white text-justify">
                                You can buy Gold Nuggets starting from 10 INR
                                from our market page. It will be automatically
                                added into your gold vault.
                              </span>
                            </details>

                            <details className="bg-black/60">
                              <summary className="bg-yellow-500 rounded-md py-2 px-2">
                                Where can I convert them?
                              </summary>
                              <span className="text-white text-justify">
                                You can convert them from "GoldSmith" in the
                                Store Page.
                              </span>
                            </details>
                          </div>
                        </div>
                      </section>
                    </div>
                  </section>
                </div>
              </div>
            ) : (
              <p className="text-[10px] text-white">0 Earned</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserCurrency;
