import React from "react";

const ShippingPolicy = () => {
  return (
    <div className="p-6 max-w-4xl mx-auto bg-white rounded-lg shadow-md sm:py-20 px-10 my-20">
      <h1 className="text-2xl font-bold mb-4">Shipping Policy</h1>
      <p className="mb-4">
        Shipping of the order from{" "}
        <a
          href="https://www.batchugold.com"
          className="text-blue-600 underline"
        >
          www.batchugold.com
        </a>{" "}
        is very safe, secure, and hassle-free. All our products are insured
        during transit by an insurance company. Every package of the product
        (Jewellry / Gold Coin) purchased from{" "}
        <a
          href="https://www.batchugold.com"
          className="text-blue-600 underline"
        >
          www.batchugold.com
        </a>{" "}
        will reach your doorstep in a durable condition and in tamper-proof
        packaging.
      </p>
      <p className="mb-4">
        Our courier partner details will be shared with the users and the
        shipping number and details are provided to customers after we ship the
        order. The selection of the courier partner will depend on their
        serviceability to the shipping address.
      </p>
      <h2 className="text-xl font-semibold mb-2">Delivery Timeline:</h2>
      <p className="mb-4">
       The deliver takes place from 10 - 15 days.
      </p>

      <h2 className="text-xl font-semibold mb-2">Shipping Charges:</h2>
      <p className="mb-4">
        We ship all orders through a trusted courier partner and shipping is
        FREE OF COST across all of India for purchases above 10000 rupees.
      </p>

      <h2 className="text-xl font-semibold mb-2">
        Delivery to Identified Person:
      </h2>
      <p className="mb-4">
        Customers are required to provide accurate details of the
        consignee/recipient’s name (as stated in their government-issued photo
        identification) along with a complete address, nearby landmark, pin
        code, and contact number for hassle-free delivery. At the time of
        delivery, the recipient will be required to provide any of the following
        identity proofs to collect the product:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Passport</li>
        <li>PAN Card</li>
        <li>Driver's License</li>
        <li>Voter's ID</li>
        <li>Aadhar Card</li>
      </ul>
      <p className="mb-4">
        To ensure safe and secure delivery, the courier agent will verify the
        recipient's identity by checking the provided original copies of the
        identity proof. The courier agent will note down the details of the
        recipient's identity proof during the delivery process. Cooperation from
        the receiver is expected during this verification process.
      </p>

      <h2 className="text-xl font-semibold mb-2">Delivery Location:</h2>
      <p className="mb-4">
        Orders can only be delivered to the recipient's residential or work
        location or any nearby jewellery partner collaborated with The Gold Jar.
        Delivery to public places such as malls, hotels, restaurants, hostels,
        or on the street is not available.
      </p>
      <p className="mb-4">
        If the recipient is unavailable during the delivery attempts, the
        courier company will make three additional attempts to deliver the
        product. If the recipient is still unreachable or unavailable, the
        product will be returned to The Batchu Gold facility and it will be
        safeguarded until the recipient reaches us.
      </p>

      <h2 className="text-xl font-semibold mb-2">
        Change in Shipping Address:
      </h2>
      <p className="mb-4">
        Customers can track their orders by logging into their My Account
        (dashboard) section on the website{" "}
        <a
          href="https://www.batchugold.com"
          className="text-blue-600 underline"
        >
          www.batchugold.com
        </a>{" "}
        or The Gold Jar application (Android) in your play store. Shipping
        details can be changed by the customer until the order is shipped from
        our facility. If the customer needs to change the shipping address, they
        should send an email to mail@batchugold.com
      </p>
      <p className="mb-4">
        The guarantee of product delivery is subject to the terms and conditions
        of the courier company. Any discrepancy in the recipient's details may
        result in non-delivery of the product.
      </p>
      <p className="mb-4">
        <a
          href="https://www.batchugold.com"
          className="text-blue-600 underline"
        >
          www.batchugold.com
        </a>{" "}
        reserves the right to change this shipping policy at any time without
        prior notice. In the event of any changes, the revised policy will be
        posted on the website.
      </p>

      <h2 className="text-xl font-semibold mb-2">Shipment Discrepancy:</h2>
      <p className="mb-4">
        Upon receiving your order, we advise you to take a video and pics and
        carefully inspect the condition of the packaging of the product. If you
        notice any damage, shortage, or over-shipment, or suspect that part of
        the shipment has been lost, do not accept the parcel and please contact
        the{" "}
        <a
          href="https://www.batchugold.com"
          className="text-blue-600 underline"
        >
          www.batchugold.com
        </a>{" "}
        Customer Care Department immediately by calling +91 95195 19581 and
        sending an official email to mail@batchugold.com
      </p>

      <h2 className="text-xl font-semibold mb-2">Damaged Products:</h2>
      <p className="mb-4">
        If you receive a damaged parcel or product, please contact{" "}
        <a
          href="https://www.batchugold.com"
          className="text-blue-600 underline"
        >
          www.batchugold.com
        </a>{" "}
        customer service department within 24 hours by calling +91 95195 19581
        and sending an email to mail@batchugold.com Do not damage or discard the
        parcel or product. Our investigation team will investigate the complaint
        once we receive it by email or phone and we take appropriate action.
        Failure to report the incident within 24 hours of receiving the parcel
        will result in us not accepting responsibility for the damages, and no
        refund or complaint will be received.
      </p>

      <h2 className="text-xl font-semibold mb-2">Missing Products:</h2>
      <p className="mb-4">
        We urge you to inspect and take a video and pictures of the condition of
        the parcel before accepting it and while opening it. Report any issues
        within 24 hours of receiving the parcel. Please contact{" "}
        <a
          href="https://www.batchugold.com"
          className="text-blue-600 underline"
        >
          www.batchugold.com
        </a>{" "}
        customer service department by calling +91 95195 19581 and sending an
        email to mail@batchugold.com if you encounter any issues. Our
        investigation team will promptly address the matter and provide a
        resolution within 7 working days. Failure to report any issues within
        the specified time frame or failure to follow the recommended inspection
        process before accepting the parcel will result in us not being able to
        entertain any complaints.
      </p>

      <h2 className="text-xl font-semibold mb-2">Non-Receipt of an Order:</h2>
      <p className="mb-4">
        At the time of dispatch, we provide the tracking ID of the shipment to
        the customer's registered email ID or to their mobile number. It is the
        customer's responsibility to track the shipment and check for any
        updates. Once the shipment is delivered, we send a delivery confirmation
        to the registered email ID.
      </p>
      <p className="mb-4">
        If you do not physically receive the parcel by the delivery time we have
        mentioned, please contact our customer service department at +91 95195
        19581 or send an email to mail@batchugold.com Our investigation team will
        promptly address the issue and provide a resolution within 7 working
        days. However, please note that failure to report any issues within the
        specified time frame might result in us not being able to entertain any
        complaints. We will also not be able to issue any refunds to customers
        in case of any chargebacks raised to the bank without following due
        process as per the regulations thereof.
      </p>

      <h2 className="text-xl font-semibold mb-2">Free Gift Packaging:</h2>
      <p className="mb-4">
        If the customer plans to purchase a gift, Batchu Gold provides gift
        packing along with a personalized gift message. The gift will be
        beautifully presented in special gift packaging. Whether the customer
        prefers to personally give the gift or have us deliver it, the recipient
        is certain to be delighted.
      </p>
      <p className="mb-4">
        To include a gift message with your purchase, simply locate the Gift
        message text box on the shopping bag/cart page. The custom message will
        be printed on a unique card and delivered alongside the gift.
      </p>
    </div>
  );
};

export default ShippingPolicy;
