import { useAuth } from "../../hooks/GlobalProvider";
import { Link } from "react-router-dom";
import {
  Godjar_home_video,
  UPI,
  referralcover,
  Goldraw,
  templecover,
  hanuman,
  GoldCoinsCover,
  bestime,
  BlackSmith,
  Referral,
  cover1,
  MarketPlacebg,
} from "../../assets/data/Imagedata";

import UserCurrency from "../../components/home/UserCurrency.jsx";
import WhyChooseUs from "../../components/home/WhyChooseUs.jsx";
import QuickBuy from "../MarketPlace/QuickBuy.jsx";
import Footer from '../../components/Footer/Footer.jsx'

const Home = () => {
  const { user } = useAuth();

  return (
    <section className="pt-16 mx-auto relative">
      <img
        title="temple The Gold Jar App"
        src={hanuman}
        alt="Aboutus"
        className="fit w-[100px] p-2 mx-auto relative top-0 opacity-40"
      />
      {/* User and Auth */}
      <section className="text-center z-10">
        <img
          title="temple The Gold Jar App"
          src={templecover}
          alt="Aboutus"
          className="w-full mx-auto bg-contain fixed top-0 -z-10 opacity-20"
        />
        <div>
          {/*After login */}
          {user ? (
            <section>
              <span className="text-yellow-500 font-bold cursor-pointer text-6xl">
                Hello,{" "}
                <span className="text-black cursor-pointer font-bold text-2xl">
                  {user.name}
                </span>
              </span>

              <section className="py-5">
                <UserCurrency />

                <div className="container mx-auto px-2">
                  <div className="grid grid-cols-3 gap-4 text-yellow-900">
                    <div className="bg-yellow-500 p-2 rounded-xl drop-shadow-md text-center border-amber-900 border shine">
                      <span className="text-[25px] font-black">100%</span>
                      <div className="-mt-2">Gold Safety</div>
                    </div>
                    <div className="bg-yellow-500 p-2 rounded-xl drop-shadow-md text-center border-amber-900 border shine">
                      <span className="text-[25px] font-black">24</span>
                      <div className="-mt-2">Karat Gold</div>
                    </div>
                    <div className="bg-yellow-500 p-2 rounded-xl drop-shadow-md text-center border-amber-900 border shine">
                      <span className="text-[25px] font-black">100%</span>
                      <div className="-mt-2">Referral</div>
                    </div>
                  </div>
                </div>

                <Link to="/MarketPlace">
                  <button className="text-xl sm:text-sm transform rounded bg-yellow-500 border border-slate-200 px-10 py-2 mt-5 font-medium text-white  transition-colors hover:bg-yellow-500 ">
                    Start investing
                  </button>
                </Link>
              </section>

              <section className="relative py-2">
                <img
                  src={referralcover}
                  alt="Gold"
                  className="w-full h-auto rounded-xl drop-shadow-lg"
                />
                <Link to="/MarketPlace">
                  <button className="absolute  text-[15px] sm:text-[35px] px-4 py-2 sm:px-4 sm:py-2 rounded bottom-8 left-1/4 transform -translate-x-1/2 bg-black text-white">
                    Start investing
                  </button>
                </Link>
              </section>

              <section>
                <h2 className="text-[35px] pt-6 font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 via-yellow-500 to-orange-500 leading-tight text-center mx-auto ">
                  SAVE EARN & GROW
                </h2>
                <p className="text-black text-xs">Explore our Features</p>
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
                  <Link
                    to="/Store"
                    className="relative rounded-lg shadow-md h-[23vh] sm:h-[40vh]"
                    style={{
                      backgroundImage: `url("${cover1}")`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="absolute bottom-0 w-full text-center bg-opacity-50 bg-black p-2">
                      <h2 className="text-[14px] font-bold text-white">
                        Store
                      </h2>
                    </div>
                  </Link>

                  <Link
                    to="/MarketPlace"
                    className="relative rounded-lg shadow-md h-[23vh] sm:h-[40vh]"
                    style={{
                      backgroundImage: `url("${MarketPlacebg}")`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="absolute bottom-0 w-full text-center bg-opacity-50 bg-black p-2">
                      <h2 className="text-[14px] font-bold text-white">
                        Market
                      </h2>
                    </div>
                  </Link>

                  <Link
                    to="/Store/referralstore"
                    className="relative rounded-lg shadow-md h-[23vh] sm:h-[40vh]"
                    style={{
                      backgroundImage: `url("${Referral}")`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="absolute bottom-0 w-full text-center bg-opacity-50 bg-black p-2">
                      <h2 className="text-[14px] font-bold text-white">
                        Referral Store
                      </h2>
                    </div>
                  </Link>

                  <Link
                    to="/Store/goldsmelter"
                    className="relative rounded-lg shadow-md h-[23vh] sm:h-[40vh]"
                    style={{
                      backgroundImage: `url("${BlackSmith}")`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="absolute bottom-0 w-full text-center bg-opacity-50 bg-black p-2">
                      <h2 className="text-[14px] font-bold text-white">
                        Gold Smith
                      </h2>
                    </div>
                  </Link>
                </div>
              </section>

              <QuickBuy />

              <Footer/>
            </section>
          ) : (
            <>
              <span className="text-yellow-500 font-bold cursor-pointer text-6xl">
                Welcome{" "}
              </span>

              {/* Saving Gold */}
              <section>
                <div className="container flex flex-col py-10 mx-auto lg:flex-row lg:items-center px-4">
                  <div className="w-full">
                    <div>
                      <h1 className="text-3xl font-bold tracking-wide text-black lg:text-5xl">
                        Save <spam className="text-yellow-500">Earn</spam> &
                        Grow !!!
                      </h1>
                      <div className="mt-8 space-y-5">
                        <p className="flex items-center -mx-2 text-black">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 mx-2 text-black"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <span className="mx-2">100% Safe Transaction</span>
                        </p>
                        <p className="flex items-center -mx-2 text-black">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 mx-2 text-black"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <span className="mx-2">100% Referral Gift</span>
                        </p>
                        <p className="flex items-center -mx-2 text-black">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 mx-2 text-black"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <span className="mx-2">
                            Gold Delivery to your Door Steps
                          </span>
                        </p>
                        <p className="flex items-center -mx-2 text-black">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 mx-2 text-black"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <p className="mx-2">
                            Anyone can buy, starting as low as ₹10
                          </p>
                        </p>
                        <p className="text-sm flex">
                          Powered by{" "}
                          <img
                            src={UPI}
                            alt="Gold"
                            className="w-[50px] h-auto ml-2"
                          />{" "}
                        </p>
                        <Link to="/MarketPlace">
                          <button className="drop-shadow-xl bg-yellow-500 text-white text-2xl px-10 py-2 rounded-xl mt-5 hover:bg-orange-500">
                            Start Saving
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "24rem",
                      zIndex: -5,
                    }}
                  >
                    <video
                      src={Godjar_home_video} // Ensure this path points to your .mp4 file
                      style={{
                        width: "500px",
                        height: "auto",
                        marginBottom: "0.5rem",
                      }}
                      autoPlay
                      muted
                      loop
                      playsInline
                    />
                  </div>
                </div>
              </section>

              {/* Why choose us? */}
              <WhyChooseUs />

              <section>
                <div className="py-5">
                  <h1 class="sm:text-3xl text-xl title-font text-center text-black font-bold">
                    Physical Gold vs Digital Gold: Which is Better?
                  </h1>
                  <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
                    <div
                      className="relative rounded-lg shadow-md h-[23vh] sm:h-[40vh]"
                      style={{
                        backgroundImage: `url("${GoldCoinsCover}")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className="absolute bottom-0 w-full text-center bg-opacity-50 bg-black p-2">
                        <h2 className="text-[14px] sm:text-[18px] font-bold text-white">
                          Purity
                        </h2>
                        <ul class="list-disc list-inside text-[8px] sm:text-[12px] text-white text-left">
                          <li>
                            <span className="font-black">Digital gold:</span>{" "}
                            99.50% pure virtual gold deposited in user wallet,
                            but 99.99% gold coins are given at the time of
                            conversion, with extra charges.
                          </li>
                          <br />
                          <li>
                            <span className="font-black">Physical gold: </span>
                            24 karat gold available in jewelry stores, with
                            purity ranging from 99.50% to 99.99%.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className="relative rounded-lg shadow-md h-[23vh] sm:h-[40vh]"
                      style={{
                        backgroundImage: `url("${GoldCoinsCover}")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className="absolute bottom-0 w-full text-center bg-opacity-50 bg-black p-2">
                        <h2 className="text-[14px] sm:text-[18px] font-bold text-white">
                          Price
                        </h2>
                        <ul class="list-disc list-inside text-[8px] sm:text-[12px] text-white text-left">
                          <li>
                            <span className="font-black">Digital gold:</span>
                            Prices are not transparent, hidden charges will be
                            included .
                          </li>
                          <li>
                            <span className="font-black">Live price:</span>
                            Live Prices are fetched from authorized bollin
                            merchants.
                          </li>
                          <li>
                            <span className="font-black">Physical gold: </span>
                            Prices vary among merchants, as they have already
                            invested in the gold.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className="relative rounded-lg shadow-md h-[23vh] sm:h-[40vh]"
                      style={{
                        backgroundImage: `url("${GoldCoinsCover}")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className="absolute bottom-0 w-full text-center bg-opacity-50 bg-black p-2">
                        <h2 className="text-[14px] sm:text-[18px] font-bold text-white">
                          Investment
                        </h2>
                        <ul class="list-disc list-inside text-[8px] sm:text-[12px] text-white text-left">
                          <li>
                            <span className="font-black">Digital gold:</span>
                            Investment possible with as low as Rs 10, with
                            flexibility in weight or amount in form of virtual
                            gold.
                          </li>
                          <br /> <br />
                          <li>
                            <span className="font-black">Physical gold:</span>
                            Minimum investment required, equivalent to the value
                            of gold to get physical gold.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className="relative rounded-lg shadow-md h-[23vh] sm:h-[40vh]"
                      style={{
                        backgroundImage: `url("${GoldCoinsCover}")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className="absolute bottom-0 w-full text-center bg-opacity-50 bg-black p-2">
                        <h2 className="text-[14px] sm:text-[18px] font-bold text-white">
                          Storage
                        </h2>
                        <ul class="list-disc list-inside text-[8px] sm:text-[12px] text-white text-left">
                          <li>
                            <span className="font-black">Digital gold:</span>
                            Virtual gold is stored in there digital vaults, but
                            only for trading purposes.
                          </li>{" "}
                          <br /> <br /> <br />
                          <li>
                            <span className="font-black">Physical gold:</span>
                            it can be stored safely but always be careful.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className=" p-4 text-sm">
                    <p>
                      <strong>Conclusion:</strong>
                      <li>
                        <span className="font-black">Digital Gold: </span>
                        It is ideal only for trading purposes, with flexibility
                        in investment amount.{" "}
                      </li>

                      <li>
                        <span className="font-black">Physical Gold: </span>
                        is suitable for saving and investing purposes, with a
                        tangible asset.
                      </li>
                    </p>
                    <p class="text-sm italic text-gray-600">
                      It's essential to allocate 10-20% of your portfolio to
                      gold, as it provides a hedge against inflation and market
                      volatility.
                    </p>

                    <p class="text-sm italic text-gray-600">
                      Remember, gold is a valuable investment, and it's crucial
                      to understand the pros and cons of both physical and
                      digital gold before making a decision.
                    </p>
                  </div>
                </div>
              </section>

              <section className="shine-gold text-[30px]  mx-2 text-center font-black text-yellow-500 ">
                THE GOLD JAR
              </section>

              <section className="shine-gold text-[20px] mx-2 text-center font-black text-yellow-500 ">
                The Gold Jar is a platform to encourage physical Gold saving
                habits to everyone by helping them to save Gold on a Daily,
                Weekly, Monthly basis.
              </section>
              <Link to="/aboutus">
                          <button className="drop-shadow-xl mb-10 bg-yellow-500 text-white text-[18px] px-8 py-2 rounded-xl mt-5 hover:bg-orange-500">
                            Know More
                          </button>
                        </Link>

              {/*what is Digital Gold */}
              <section>
                <div className=" flex flex-col cursor-default section relative pt-10 pb-8 md:pt-16 md:pb-10 bg-gradient-to-r from-yellow-400 via-yellow-500 to-orange-500 rounded-2xl">
                  <div className=" flex flex-col items-center mx-auto ">
                    <div className="flex flex-col gap-7">
                      <div className="flex flex-col gap-2 px-4 text-center w-12/12 mx-auto">
                        <h2 className="md:text-6xl text-4xl font-extrabold leading-tight text-white">
                          What is Digital{" "}
                          <spam className="text-white">gold?</spam>
                        </h2>
                        <p className=" text-white">
                          It is a form of investment where gold is purchased and
                          stored in a digital format. Investors own the gold but
                          do not take physical possession of it. Instead, the
                          gold is securely stored by the service provider.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="relative py-2">
                <img
                  src={referralcover}
                  alt="Gold"
                  className="w-full h-auto rounded-xl drop-shadow-lg"
                />
                <Link to="/MarketPlace">
                  <button className="absolute  text-[15px] sm:text-[35px] px-4 py-2 sm:px-4 sm:py-2 rounded bottom-8 left-1/4 transform -translate-x-1/2 bg-black text-white">
                    Start investing
                  </button>
                </Link>
              </section>

              <section className="text-white rounded-full">
                <div
                  className="relative py-20 sm:py-40"
                  style={{
                    backgroundImage: `url(${bestime})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <div className="absolute inset-0 bg-black opacity-70"></div>
                  <div className="relative z-10 text-center">
                    <h3 className="text-[30px] mt-2 uppercase font-black text-yellow-500">
                      Best Time to Buy Gold
                    </h3>
                    <h2 className="text-[30px] font-black  text-yellow-500">
                      In Indian culture,
                    </h2>
                    buying gold is considered auspicious throughout the year,
                    from January to December. So, you can buy gold at any time,
                    considering your needs and budget.
                    <p className="px-5 text-[12px]">
                      Before making a gold purchase, it's essential to check the
                      current price. Gold prices fluctuate due to various
                      social, political, and economic factors.
                    </p>
                  </div>
                </div>
              </section>

              {/* Start investing */}
              <section className=" mx-auto p-10  bg-gradient-to-r from-yellow-400 to-orange-400 rounded-2xl">
                <img
                  src={Goldraw}
                  alt="Gold"
                  className="w-[25%] md:w-[15%] h-auto mx-auto m-4 absolute left-0 -mt-24"
                />
                <h2 className="sm:text-center cursor-default pb-2 font-extrabold text-transparent text-[30px]  text-white mt-3  tracking-tight ">
                  Start investing in Gold Today
                </h2>
                <p className="sm:text-center cursor-default text-[20px] font-medium text-white  mb-10">
                  Never miss Gold Price Drop , Save Earn & Grow
                </p>

                <div className="sm:text-center  justify-center flex gap-4 flex-wrap">
                  <Link to="/MarketPlace">
                    <button className="text-xl sm:text-sm transform rounded bg-white border border-slate-200 px-10 py-2 font-medium text-black hover:text-white transition-colors hover:bg-black ">
                      Start Now
                    </button>
                  </Link>
                </div>
              </section>

              {/* FAQ */}
              <section>
                <div>
                  <section class="text-black pt-10">
                    <div class="container px-5 mx-auto">
                      <div class="text-center">
                        <h2 class="sm:text-3xl text-2xl text-center font-bold text-black mb-4">
                          All you need to Know About{" "}
                          <span className="text-yellow-500">Gold</span>
                        </h2>
                      </div>
                      <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                        <div class="w-full lg:w-1/2 px-4 py-2">
                          <details class="mb-4">
                            <summary class="font-semibold bg-yellow-500 rounded-md py-2 px-4">
                              Why Invest in Gold?
                            </summary>
                            <span className="text-black">
                              <ul className="list-disc text-[12px] list-inside">
                                <li>
                                  Hedge against inflation and market volatility
                                </li>
                                <li>Store of value and wealth</li>
                                <li>Diversification of investment portfolio</li>
                                <li>Liquidity and ease of sale</li>
                                <li>Tangible asset with intrinsic value</li>
                              </ul>
                            </span>
                          </details>
                          <details class="mb-4">
                            <summary class="font-semibold bg-yellow-500 rounded-md py-2 px-4">
                              What are the Types of Gold Investments?
                            </summary>
                            <span className="text-black">
                              <p className="text-[12px]">
                                Investors can choose from various gold
                                investment options, each with its unique
                                features and benefits:
                              </p>
                              <ul className="list-disc list-inside">
                                <li className="text-[16px] font-semibold mb-2">
                                  Physical Gold (coins, bars, jewelry)
                                </li>
                                <li className="text-[16px] font-semibold mb-2">
                                  Digital Gold (online platforms, apps)
                                </li>
                                <li className="text-[16px] font-semibold mb-2">
                                  Gold ETFs (Exchange-Traded Funds)
                                </li>
                                <li className="text-[16px] font-semibold mb-2">
                                  Gold Mutual Funds
                                </li>
                                <li className="text-[16px] font-semibold mb-2">
                                  Gold Futures Contracts
                                </li>
                                <li className="text-[16px] font-semibold mb-2">
                                  Sovereign Gold Bonds (SGBs)
                                </li>
                              </ul>
                            </span>
                          </details>

                          <details class="mb-4">
                            <summary class="font-semibold bg-yellow-500 rounded-md py-2 px-4">
                              Factors Affecting Gold Prices
                            </summary>
                            <span className="text-black">
                              <li>
                                Inflation and recession: Gold prices tend to
                                rise during these periods as people use gold to
                                hedge their portfolios against inflation.
                              </li>
                              <li>
                                Seasonal demand: Gold prices often increase
                                during certain months due to high demand.
                              </li>
                            </span>
                          </details>

                          <details class="mb-4">
                            <summary class="font-semibold bg-yellow-500 rounded-md py-2 px-4">
                              Why do Indians Prefer Gold Investments?
                            </summary>
                            <span className="text-black">
                              Indians have a long-standing affinity for gold,
                              which plays a significant role in our cultural and
                              financial landscape. Gold is considered an
                              auspicious investment, incorporated into various
                              festivals and relied upon as a primary investment
                              option when excess funds are available. Gold's
                              appeal lies in its reputation as a stable store of
                              wealth, providing strong long-term returns,
                              serving as a hedge against inflation, and offering
                              a valuable diversification option for investment
                              portfolios. While physical gold remains the
                              dominant preference, digital gold alternatives,
                              such as gold ETFs, sovereign bonds, and digital
                              gold, are gaining traction.
                            </span>
                          </details>
                        </div>
                        <div class="w-full lg:w-1/2 px-4 py-2">
                          <details class="mb-4">
                            <summary class="font-semibold bg-orange-500 rounded-md py-2 px-4">
                              What are Digital Gold Products?
                            </summary>
                            <span className="text-black">
                              <ul className="list-disc text-[12px] list-inside mb-4">
                                <li>
                                  Store money in a digital wallet or vault in
                                  the form of virtual gold
                                </li>
                                <li>
                                  No worry about theft, but no physical gold
                                  possession
                                </li>
                                <li>
                                  High fees for converting digital gold to
                                  physical gold
                                </li>
                                <li>Lack of transparency</li>
                                <li>
                                  Minimum investment: Rs 10 (varies across apps)
                                </li>
                                <li>
                                  Charges: hidden fees, storage fees, and
                                  conversion fees
                                </li>
                              </ul>
                            </span>
                          </details>
                          <details class="mb-4">
                            <summary class="font-semibold bg-orange-500 rounded-md py-2 px-4">
                              Gold ETFs (Exchange-Traded Funds)
                            </summary>
                            <span className="text-black">
                              <ul className="list-disc text-[12px] list-inside mb-4">
                                <li>Mutual funds investing in gold bullion</li>
                                <li>Track the price of physical gold</li>
                                <li>
                                  One unit equals one gram of gold (backed by
                                  physical gold)
                                </li>
                                <li>
                                  Trade on stock exchanges, requiring a demat
                                  account
                                </li>
                                <li>No physical gold delivery upon sale</li>
                                <li>
                                  Minimum investment: Rs 1,000 (varies across
                                  funds)
                                </li>
                                <li>
                                  Charges: management fees, exit loads, and
                                  brokerage fees
                                </li>
                              </ul>
                            </span>
                          </details>
                          <details class="mb-4">
                            <summary class="font-semibold bg-orange-500 rounded-md py-2 px-4">
                              Gold Mutual Funds
                            </summary>
                            <span className="text-black">
                              <ul className="list-disc text-[12px] list-inside mb-4">
                                <li>Invest directly or indirectly in gold</li>
                                <li>
                                  Open-ended funds, purchasable without a demat
                                  account
                                </li>
                                <li>
                                  Liquid, with the option to sell immediately
                                </li>
                                <li>
                                  Fee: expense ratio (deducted from profits)
                                </li>
                                <li>
                                  Minimum investment: Rs 500, with SIP options
                                </li>
                                <li>
                                  Charges: management fees, exit loads, and
                                  brokerage fees
                                </li>
                              </ul>
                            </span>
                          </details>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>

              <>
                <div className="min-w-screen min-h-screen bg-gray-50 flex items-center justify-center py-5">
                  <div className="w-full bg-white border-t border-b border-gray-200 px-5 py-16 md:py-24 text-gray-800">
                    <div className="w-full max-w-6xl mx-auto">
                      <div className="text-center max-w-xl mx-auto">
                        <h1 className="text-6xl md:text-7xl font-bold mb-5 text-gray-600">
                          What people <br />
                          are saying.
                        </h1>
                        <div className="text-center mb-10">
                          <span className="inline-block w-1 h-1 rounded-full bg-orange-500 ml-1" />
                          <span className="inline-block w-3 h-1 rounded-full bg-orange-500 ml-1" />
                          <span className="inline-block w-40 h-1 rounded-full bg-yellow-500" />
                          <span className="inline-block w-3 h-1 rounded-full bg-orange-500 ml-1" />
                          <span className="inline-block w-1 h-1 rounded-full bg-orange-500 ml-1" />
                        </div>
                      </div>
                      <div className="-mx-3 md:flex items-start">
                        <div className="px-3 md:w-1/3">
                          <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                            <div className="w-full flex mb-4 items-center">
                              <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                                <img
                                  src="https://i.pravatar.cc/100?img=1"
                                  alt=""
                                />
                              </div>
                              <div className="flex-grow pl-3">
                                <h6 className="font-bold text-sm uppercase text-gray-600">
                                  Kavya
                                </h6>
                                <div className="flex">
                                  {[...Array(5)].map((_, i) => (
                                    <svg
                                      key={i}
                                      className="w-4 h-4 text-yellow-500 fill-current"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M10 15l-5.878 3.09 1.122-6.54L.488 6.41l6.568-.957L10 0l2.944 5.453 6.568.957-4.756 4.14 1.122 6.54z" />
                                    </svg>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="w-full">
                              <p className="text-sm leading-tight">
                                <span className="text-lg leading-none italic font-bold text-gray-400 mr-1">
                                  "
                                </span>
                                I've been using The Gold Jar for a few months
                                now, and I must say, it's an excellent platform
                                for buying gold. Starting at just 10 rupees,
                                it's perfect for someone like me who wants to
                                invest in small amounts. Plus, the ability to
                                earn referral ant points makes it even more
                                rewarding!
                                <span className="text-lg leading-none italic font-bold text-gray-400 ml-1">
                                  "
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                            <div className="w-full flex mb-4 items-center">
                              <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                                <img
                                  src="https://i.pravatar.cc/100?img=2"
                                  alt=""
                                />
                              </div>
                              <div className="flex-grow pl-3">
                                <h6 className="font-bold text-sm uppercase text-gray-600">
                                  Ravi Raj
                                </h6>
                                <div className="flex">
                                  {[...Array(5)].map((_, i) => (
                                    <svg
                                      key={i}
                                      className="w-4 h-4 text-yellow-500 fill-current"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M10 15l-5.878 3.09 1.122-6.54L.488 6.41l6.568-.957L10 0l2.944 5.453 6.568.957-4.756 4.14 1.122 6.54z" />
                                    </svg>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="w-full">
                              <p className="text-sm leading-tight">
                                <span className="text-lg leading-none italic font-bold text-gray-400 mr-1">
                                  "
                                </span>
                                The Gold Jar app is a game-changer! I love how
                                easy it is to order gold coins directly from my
                                phone. The referral ant points system is a nice
                                touch too – I've already earned quite a few
                                points just by referring my friends!
                                <span className="text-lg leading-none italic font-bold text-gray-400 ml-1">
                                  "
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="px-3 md:w-1/3">
                          <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                            <div className="w-full flex mb-4 items-center">
                              <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                                <img
                                  src="https://i.pravatar.cc/100?img=3"
                                  alt=""
                                />
                              </div>
                              <div className="flex-grow pl-3">
                                <h6 className="font-bold text-sm uppercase text-gray-600">
                                  Pavan Satya
                                </h6>
                                <div className="flex">
                                  {[...Array(5)].map((_, i) => (
                                    <svg
                                      key={i}
                                      className="w-4 h-4 text-yellow-500 fill-current"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M10 15l-5.878 3.09 1.122-6.54L.488 6.41l6.568-.957L10 0l2.944 5.453 6.568.957-4.756 4.14 1.122 6.54z" />
                                    </svg>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="w-full">
                              <p className="text-sm leading-tight">
                                <span className="text-lg leading-none italic font-bold text-gray-400 mr-1">
                                  "
                                </span>
                                Investing in gold has never been this
                                convenient. With The Gold Jar, I can start with
                                as little as 10 rupees and gradually increase my
                                holdings. The app is user-friendly, and the
                                referral program adds great value. Highly
                                recommended!
                                <span className="text-lg leading-none italic font-bold text-gray-400 ml-1">
                                  "
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                            <div className="w-full flex mb-4 items-center">
                              <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                                <img
                                  src="https://i.pravatar.cc/100?img=4"
                                  alt=""
                                />
                              </div>
                              <div className="flex-grow pl-3">
                                <h6 className="font-bold text-sm uppercase text-gray-600">
                                  Rahul Reddy
                                </h6>
                                <div className="flex">
                                  {[...Array(5)].map((_, i) => (
                                    <svg
                                      key={i}
                                      className="w-4 h-4 text-yellow-500 fill-current"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M10 15l-5.878 3.09 1.122-6.54L.488 6.41l6.568-.957L10 0l2.944 5.453 6.568.957-4.756 4.14 1.122 6.54z" />
                                    </svg>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="w-full">
                              <p className="text-sm leading-tight">
                                <span className="text-lg leading-none italic font-bold text-gray-400 mr-1">
                                  "
                                </span>
                                The Gold Jar is fantastic! I've bought several
                                gold coins through the app, and the process is
                                always smooth and hassle-free. Earning referral
                                ant points is a fun bonus that makes me more
                                excited to use the app and share it with others.
                                <span className="text-lg leading-none italic font-bold text-gray-400 ml-1">
                                  "
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="px-3 md:w-1/3">
                          <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                            <div className="w-full flex mb-4 items-center">
                              <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                                <img
                                  src="https://i.pravatar.cc/100?img=5"
                                  alt=""
                                />
                              </div>
                              <div className="flex-grow pl-3">
                                <h6 className="font-bold text-sm uppercase text-gray-600">
                                  Sai Kumar
                                </h6>
                                <div className="flex">
                                  {[...Array(5)].map((_, i) => (
                                    <svg
                                      key={i}
                                      className="w-4 h-4 text-yellow-500 fill-current"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M10 15l-5.878 3.09 1.122-6.54L.488 6.41l6.568-.957L10 0l2.944 5.453 6.568.957-4.756 4.14 1.122 6.54z" />
                                    </svg>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="w-full">
                              <p className="text-sm leading-tight">
                                <span className="text-lg leading-none italic font-bold text-gray-400 mr-1">
                                  "
                                </span>
                                I appreciate how The Gold Jar allows small
                                investments starting at 10 rupees. It's perfect
                                for new investors who want to dip their toes in
                                gold buying. The app is intuitive, and the
                                referral points system is a great incentive to
                                spread the word.
                                <span className="text-lg leading-none italic font-bold text-gray-400 ml-1">
                                  "
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                            <div className="w-full flex mb-4 items-center">
                              <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                                <img
                                  src="https://i.pravatar.cc/100?img=6"
                                  alt=""
                                />
                              </div>
                              <div className="flex-grow pl-3">
                                <h6 className="font-bold text-sm uppercase text-gray-600">
                                  Vihari
                                </h6>
                                <div className="flex">
                                  {[...Array(5)].map((_, i) => (
                                    <svg
                                      key={i}
                                      className="w-4 h-4 text-yellow-500 fill-current"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M10 15l-5.878 3.09 1.122-6.54L.488 6.41l6.568-.957L10 0l2.944 5.453 6.568.957-4.756 4.14 1.122 6.54z" />
                                    </svg>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="w-full">
                              <p className="text-sm leading-tight">
                                <span className="text-lg leading-none italic font-bold text-gray-400 mr-1">
                                  "
                                </span>
                                I've tried other gold buying apps, but The Gold
                                Jar stands out with its low entry point and easy
                                gold coin ordering. The referral ant points
                                system is an added bonus that keeps me engaged
                                and motivated to refer friends and family. Love
                                it!
                                <span className="text-lg leading-none italic font-bold text-gray-400 ml-1">
                                  "
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </>
          )}
        </div>
      </section>
    </section>
  );
};

export default Home;
