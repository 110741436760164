// ImageSlider.js
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  MarketBanner,
  Guarantee,
  Goldraw,
  MarketPlacebg,
} from "../../assets/data/Imagedata";
import { Link } from "react-router-dom";

// Define the slides data
const slidesData = [
  {
    Text: "QUICK BUY",
    description: "Save in 24K Gold, 10₹.",
    url: "/MarketPlace/gold-buying",
    imgSrc: Goldraw,
    imgSize: { width: "20%", height: "auto" }, // Adjusted width
    bgGradient: "linear-gradient(to right, #facc15 ,#ca8a04)",
  },
  {
    Text: "DAILY SAVE",
    description: "Coming soon Automate Your Savings Daily, 24 karat gold.",
    url: "/MarketPlace/gold-buying-daily",
    imgSrc: Goldraw,
    imgSize: { width: "20%", height: "auto" }, // Adjusted width
    bgGradient: "linear-gradient(to right, #0ea5e9, #0369a1)",
  },
  {
    Text: "PLAN WEEKLY",
    description: "Coming soon Automate Your Savings Weekly, 24 karat gold.",
    url: "/MarketPlace/gold-buying-weekly",
    imgSrc: Goldraw,
    imgSize: { width: "20%", height: "auto" }, // Adjusted width
    bgGradient: "linear-gradient(to right, #f97316, #c2410c)",
  },
  {
    Text: "PLAN MONTHLY",
    description: "Coming soon Automate Your Savings Monthly, 24 karat gold.",
    url: "",
    imgSrc: Goldraw,
    imgSize: { width: "20%", height: "auto" }, // Adjusted width
    bgGradient: "linear-gradient(to right, #84552b, #341705)",
  },
];

const ImageSlider = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 768);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1.1, // Show 1 full slide and a small part of the next slide
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
  };

  return (
    <section
      className="min-h-screen flex flex-col  items-center justify-center overflow-hidden bg-gradient-to-r from-yellow-950 via-red-800 to-yellow-950 relative "
      style={{
        backgroundImage: `url("${MarketPlacebg}")`,
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
    >
      <div className="absolute w-full h-screen opacity-40 bg-black " />
      <img
        title="Store"
        src={MarketBanner}
        alt="antmarketbanner"
        className="w-[80%] md:w-[30%] h-auto drop-shadow-md absolute top-20 "
        style={{ zIndex: 1 }}
      />

      <section className="w-full mx-auto text-center relative z-10">
        <div className="mb-6">
          <h2 className="text-3xl font-bold text-white font-serif drop-shadow-[0_4px_4px_rgba(0,0,0,0.75)]">
            Explore Our Top Features
          </h2>
          <p className="text-white text-xs">
            Save to watch your wealth grow. Start saving gold from 10₹.
          </p>
        </div>
        {isSmallScreen ? (
          <Slider {...settings}>
            {slidesData.map((slide, index) => (
              <div key={index} className="px-4">
                <img
                  src={slide.imgSrc}
                  alt="Item"
                  className="rounded-t-lg mx-auto h-auto -mb-5 drop-shadow-xl"
                  style={slide.imgSize}
                />
                <div
                  style={{ background: slide.bgGradient }}
                  className="rounded-lg shadow-md p-4"
                >
                  <div className="text-center mt-4">
                    <div className="text-white font-bold text-xl mt-2 drop-shadow-[0_4px_4px_rgba(0,0,0,0.75)]">
                      {slide.Text}
                    </div>

                    <div className="text-white text-sm">
                      {slide.description}
                    </div>
                    <Link to={slide.url}>
                      <button className="bg-white border-black px-10 rounded-lg hover:bg-black hover:text-white py-2 text-black mt-4">
                        Save Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {slidesData.map((slide, index) => (
              <div key={index} className="p-2">
                <img
                  src={slide.imgSrc}
                  alt="Item"
                  className="rounded-t-lg mx-auto h-auto -mb-5 drop-shadow-xl"
                  style={slide.imgSize}
                />
                <div
                  style={{ background: slide.bgGradient }}
                  className="rounded-lg shadow-md p-4 "
                >
                  <div className="text-center mt-4">
                    <div className="text-white font-bold text-xl mt-2">
                      {slide.Text}
                    </div>
                    <div className="text-white text-md">
                      {slide.description}
                    </div>
                    <Link to={slide.url}>
                      <button className="bg-white border-black px-10 rounded-lg hover:bg-black hover:text-white py-2 text-black mt-4">
                        Save Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="mt-10">
          <img
            src={Guarantee}
            alt="guarantee"
            className="w-[60%] md:w-[20%] mx-auto h-auto pt-10"
          />
          <p id="copyright" className="text-center pt-1 text-[10px] text-white">
            <span className="font-bold">© Batchu Gold</span> - CopyRights
            Reserved
          </p>
        </div>
      </section>
    </section>
  );
};

export default ImageSlider;
