// ApiUrl.js
const SINGUP_API = "https://batchugold.com/apis/register/signup.php";
const SINGIN_API = "https://batchugold.com/apis/register/signin.php";

const CRUD_API = "https://batchugold.com/apis/CRUD.php";
const USER_FETCH_TRANSACTIONS_URL = "https://batchugold.com/apis/fetchTransactions.php";
const Admin_CRUD_TRANSACTIONS_URL = "https://batchugold.com/apis/CRUDTransactions.php";
const Admin_CRUD_SUBSCRIPTION_URL = "https://batchugold.com/apis/Subscriptions/CRUDSubscription.php";
const Admin_CRUD_SUBSCRIPTION_TRANSATIONS_URL="https://batchugold.com/apis/Subscriptions/CRUDSubscriptionTransations.php";


const USER_DELETE= "https://batchugold.com/apis/users/deleteUser.php";
const USER_CURRENCY_ANTS= "https://batchugold.com/apis/USER_CURRENCY_ANTS.php";
const CRUDUsers= "https://batchugold.com/apis/users/CRUDUsers.php";


const GOLD_LIVE_PRICE= "https://batchugold.com/apis/LiveGoldApi.php"

const GST_MAKING_CHARGES= 'https://batchugold.com/apis/PriceUpdates/TaxCharges.php'


const PHONPE_CREATE_SUBSCRIPTION= 'https://batchugold.com/apis/PhonePe/DigitalGold/Daily/PhonePeSubscriptions.php '

export { SINGUP_API,SINGIN_API,
    CRUD_API, USER_FETCH_TRANSACTIONS_URL,
    GOLD_LIVE_PRICE ,GST_MAKING_CHARGES,PHONPE_CREATE_SUBSCRIPTION,
    Admin_CRUD_TRANSACTIONS_URL,
    Admin_CRUD_SUBSCRIPTION_URL,Admin_CRUD_SUBSCRIPTION_TRANSATIONS_URL,
    USER_CURRENCY_ANTS,USER_DELETE,CRUDUsers
};
