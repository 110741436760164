import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { Navbar, PrivacyPolicy, TermsnConditions, Disclaimer, ShippingandDelivery, ReturnPolicy, Error404, 
        Home, Aboutus, Contactus, ProfileEdit,
        
        MarketPlace,GoldBuyingDaily,Verifyupi,SubmitAuthReq,
        
        GoldBuying,GoldBuyingWeekly,GoldBuyingMonthly,
        ProductPost,AllPaymentTransation, AllGoldSmithHistory,AllReferralHistory,AllStoreHistory,LiveGoldPriceChange,AllSubscriptions, AllSubscriptionsTransations } from './routes/Routes';

import Store from './routes/Store/Store';
import GoldCoins from "./routes/Store/GoldCoins";
import Cart from './routes/Store/Cart';
import Checkout from './routes/Store/Checkout'
import ReferralStore from './routes/Store/ReferralStore'
import GoldSmelter from './routes/Store/GoldSmelter/GoldSmelter'

import UserCurrencySummery from './routes/user/UserCurrencySummery'

import Signin from './routes/auth/Signin';
import Register from './routes/auth/register';
import ForgotPassword from './routes/auth/ForgotPassword'
import { useAuth } from './hooks/GlobalProvider';
import Dashboard from "./routes/user/Dashboard";
import PaymentHistory from './routes/user/PaymentHistory';

import Invoice from './routes/Admin/Invoice'
import AllUsers from "./routes/Admin/AllUsers";
import LogoLoader from "./routes/LogoLoader";



const App = () => {
  const location = useLocation();
  const { user, loading } = useAuth();
  
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(savedCart);
  }, []);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  if (loading) {
    return <LogoLoader/>
  }

  return (
    <>
      <Navbar />
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsconditions" element={<TermsnConditions />} />
        <Route path="/returnpolicy" element={<ReturnPolicy />} />
        <Route path="/shippingdelivery" element={<ShippingandDelivery />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="/user/dashboard" element={<Dashboard />} />
        <Route path="/user/paymenthistory" element={<PaymentHistory />} />
        <Route path="/user/profileedit" element={<ProfileEdit />} />
        <Route path="/user/currency" element={user ? <UserCurrencySummery /> : <Navigate to="/signin" />} />
       
        <Route path="/Admin/ProductPost" element={user ? <ProductPost /> : <Navigate to="/signin" />} />
        <Route path="/Admin/AllPaymentTransation" element={user ? <AllPaymentTransation /> : <Navigate to="/signin" />} />
        <Route path="/Admin/AllGoldSmithHistory" element={user ? <AllGoldSmithHistory /> : <Navigate to="/signin" />} />
        <Route path="/Admin/AllReferralHistory" element={user ? <AllReferralHistory /> : <Navigate to="/signin" />} />
        <Route path="/Admin/AllStoreHistory" element={user ? <AllStoreHistory /> : <Navigate to="/signin" />} />
        <Route path="/Admin/LiveGoldPriceChange" element={user ? <LiveGoldPriceChange /> : <Navigate to="/signin" />} />
        <Route path="/Admin/AllSubscriptions" element={user ? <AllSubscriptions /> : <Navigate to="/signin" />} />
        <Route path="/Admin/AllSubscriptionsTransations" element={user ? <AllSubscriptionsTransations /> : <Navigate to="/signin" />} />
        <Route path="/Admin/AllUsers" element={ <AllUsers />} />
        <Route path="/invoice" element={<Invoice />} />

        <Route path="/MarketPlace" element={<MarketPlace />} />
        <Route path="/MarketPlace/gold-buying" element={user ? <GoldBuying /> : <Navigate to="/signin" />} />

        <Route path="/MarketPlace/gold-buying-daily" element={user ? <GoldBuyingDaily /> : <Navigate to="/signin" />} />
        <Route path="/MarketPlace/verifyupi" element={user ? <Verifyupi /> : <Navigate to="/signin" />} />
        <Route path="/MarketPlace/submit-auth-req" element={user ? <SubmitAuthReq /> : <Navigate to="/signin" />} />

        <Route path="/MarketPlace/gold-buying-weekly" element={user ? <GoldBuyingWeekly /> : <Navigate to="/signin" />} />
        <Route path="/MarketPlace/gold-buying-monthly" element={user ? <GoldBuyingMonthly /> : <Navigate to="/signin" />} />

        <Route path="/Store" element={<Store />} />
        <Route path="/Store/GoldCoins" element={user ? <GoldCoins /> : <Navigate to="/signin" />} />
        <Route path="/Store/cart" element={user ? <Cart /> : <Navigate to="/signin" />} />
        <Route path="/Store/checkout" element={user ? <Checkout /> : <Navigate to="/signin" />} />
        <Route path="/Store/referralstore" element={user ? <ReferralStore /> : <Navigate to="/signin" />} />
        <Route path="/Store/goldsmelter" element={user ? <GoldSmelter /> : <Navigate to="/signin" />} />



        <Route path="/*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default App;
