import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../hooks/GlobalProvider";
import { USER_CURRENCY_ANTS } from "../../hooks/APIHooks";
import { Link, useLocation } from "react-router-dom";
import { GoldCoin_BAT, Ant, Goldraw } from "../../assets/data/Imagedata";

const UserCurrency = () => {
  const { user } = useAuth();
  const [currency, setCurrency] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  const fetchCurrency = async (email) => {
    try {
      const response = await axios.get(
        `${USER_CURRENCY_ANTS}?emailaddress=${email}`
      );
      setCurrency(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user || !user.emailaddress) return;
    setLoading(true);
    fetchCurrency(user.emailaddress);
  }, [user, location]);

  return (
    <section className=" rounded-2xl my-2  ">
      <div className="grid grid-cols-2 gap-4 py-2 ">
        {/* Ant Points Section */}
        <div className="relative h-full items-center justify-end ">
          <div className="relative z-10 text-right ">
            {currency ? (
              <div className="text-center p-1 ">
                <img
                  src={Ant}
                  alt="Ant Points"
                  className="w-[100px] h-auto justify-center mx-auto"
                />
                <p className="text-yellow-900 text-[20px] font-bold">
                  {currency.ants}
                </p>
                <p className="text-[12px] text-yellow-900 font-bold">Ant Points</p>
                <div className="text-left"></div>
              </div>
            ) : (
              <p className="text-[10px] text-yellow-900">0 Earned</p>
            )}
          </div>
        </div>

        {/* Gold Nuggets Section */}
        <div className="relative h-full items-center justify-end">
          <div className="relative z-10 text-right">
            {currency ? (
              <div className="text-center p-1">
                <img
                  src={Goldraw}
                  alt="Gold Nuggets"
                  className="w-[100px] h-auto justify-center mx-auto"
                />
                <p className="text-yellow-900 text-[20px] font-bold">
                  {parseFloat(currency.gold_grams)}
                </p>
                <p className="text-[12px] text-yellow-900 font-bold">
                  Gold Nuggets (Gold Flakes)
                </p>
                <div className="text-left"></div>
              </div>
            ) : (
              <p className="text-[10px] text-yellow-900">0 Earned</p>
            )}
          </div>
        </div>

        
      </div>
    </section>
  );
};

export default UserCurrency;
