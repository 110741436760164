import React, { useEffect, useState } from "react";
import { useAuth } from "../../hooks/GlobalProvider";
import { RiArrowGoBackFill, RiStore3Fill } from "react-icons/ri";
import { Link } from "react-router-dom";

import { USER_DELETE, USER_CURRENCY_ANTS, CRUDUsers } from "../../hooks/APIHooks";

function AllUsers() {
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const usersPerPage = 10;

  useEffect(() => {
    if (user && user.role === "admin") {
      const fetchUsers = async () => {
        try {
          const [usersResponse, currencyResponse] = await Promise.all([
            fetch(CRUDUsers, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ role: user.role }),
            }),
            fetch(USER_CURRENCY_ANTS),
          ]);

          const usersData = await usersResponse.json();
          const currencyData = await currencyResponse.json();

          if (usersData.success) {
            // Merge usersData with currencyData
            const mergedData = usersData.users.map((user) => {
              const currency = currencyData.find(
                (item) => item.emailaddress === user.emailaddress
              );
              return {
                ...user,
                ants: currency ? currency.ants : 0,
                gold_grams: currency ? currency.gold_grams : 0,
              };
            });

            setUsers(mergedData);
          } else {
            setError("Failed to fetch users");
          }
        } catch (error) {
          setError("Error fetching data");
        }
        setLoading(false);
      };

      fetchUsers();
    } else {
      setError("Unauthorized access");
      setLoading(false);
    }
  }, [user]);

  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (!confirmed) return;

    try {
      const response = await fetch(USER_DELETE, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });

      const data = await response.json();

      if (data.success) {
        setUsers(users.filter((user) => user.id !== id));
      } else {
        alert("Failed to delete user");
      }
    } catch (error) {
      alert("Error deleting user");
    }
  };

  const renderTable = (users) => {
    const startIndex = currentPage * usersPerPage;
    const selectedUsers = users.slice(startIndex, startIndex + usersPerPage);

    return (
      <div className="bg-white w-full shadow-lg rounded-md p-4 sm:p-6 my-12">
        <h2 className="text-xl sm:text-2xl font-bold mb-4 text-center text-gray-800">
          All Users
        </h2>
        {selectedUsers.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white divide-y divide-gray-200">
              <tbody className="bg-white divide-y divide-gray-200">
                {selectedUsers.map((user) => (
                  <tr key={user.id}>
                    <td className="grid grid-cols-2 gap-4 p-4 text-black">
                      <div className="bg-gray-200 text-[12px]">ID: {user.id}</div>
                      <div className="text-[12px]">{user.emailaddress}</div>
                      <div className="text-[12px]">
                        Ants: {user.ants} | Gold Grams: {user.gold_grams}
                      </div>
                      <div className="text-[14px] font-bold">{user.name}</div>
                      <div className="text-[12px]">
                        {user.phonenumber} | {user.sex}
                      </div>
                    </td>

                    <td className="py-2 px-2 text-[12px] text-gray-700">
                      <button
                        onClick={() => handleDelete(user.id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        X
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center text-gray-600">No users found.</p>
        )}
        <div className="flex justify-between mt-4">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
            disabled={currentPage === 0}
            className="px-4 py-2 bg-gray-300 rounded-md disabled:bg-gray-200"
          >
            Previous
          </button>
          <button
            onClick={() =>
              setCurrentPage((prev) =>
                (prev + 1) * usersPerPage < users.length ? prev + 1 : prev
              )
            }
            disabled={(currentPage + 1) * usersPerPage >= users.length}
            className="px-4 py-2 bg-gray-300 rounded-md disabled:bg-gray-200"
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  return (
    <section className="py-10 sm:py-16 px-2 sm:px-6">
      <div className="flex justify-center">
        <div className="w-full max-w-6xl">
          <div className="flex flex-col items-center justify-center w-full">
            {loading ? (
              <div className="flex items-center justify-center h-48 text-red-500">
                Loading...
              </div>
            ) : error ? (
              <div className="flex items-center justify-center h-48 text-red-500">
                {error}
              </div>
            ) : (
              renderTable(users)
            )}
          </div>
        </div>
      </div>
      {/* Navigation Links */}
      <div className="flex justify-center fixed bottom-0 w-full p-2 z-10">
        <div className="flex flex-row justify-around w-full max-w-md p-2 bg-yellow-500 mx-2 rounded-full">
          <div className="flex flex-col items-center group">
            <Link
              to="/user/dashboard"
              className="flex flex-col items-center px-4 rounded-full text-white"
            >
              <RiArrowGoBackFill className="h-6 w-6 mb-1 text-white" />
              <span className="text-[10px]">BACK</span>
            </Link>
          </div>
          <div className="flex flex-col items-center group">
            <Link
              to="/"
              className="flex flex-col items-center px-4 rounded-full text-white"
            >
              <RiStore3Fill className="h-6 w-6 mb-1 text-white" />
              <span className="text-[10px]">HOME</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AllUsers;
