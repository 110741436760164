import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MarketPlacebg } from '../../assets/data/Imagedata';

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [emailAddress, setEmailAddress] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const onSubmit = async () => {
    setError("");
    setMessage("");
    try {
      const response = await fetch('https://batchugold.com/apis/forgetPassword.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          email: emailAddress
        })
      });
  
      const data = await response.json();
  
      if (response.ok) {
        if (data.status === 'success') {
          setMessage(data.message);
          navigate('/signin');
        } else {
          setError(data.message);
        }
      } else {
        setError('Failed to communicate with the server.');
      }
    } catch (err) {
      console.error("Error:", err);
      setError('An error occurred. Please try again.');
    }
  };
  

  return (
    <section className="min-h-screen flex items-center justify-center bg-gradient-to-r from-yellow-950 via-red-800 to-yellow-950" style={{
      backgroundImage: `url("${MarketPlacebg}")`,
      backgroundSize: "cover",
      backgroundPosition: "top",
    }}>
      <div className="flex flex-col items-center justify-center min-h-screen p-6">
        <div className="w-full max-w-md bg-white p-8 m-20 rounded-lg shadow-md">
          <h1 className="text-2xl font-bold text-center mb-4">Forgot Your Password?</h1>
          <p className="text-xs text-center mb-4">Enter your email address below and we'll send your password to you.</p>
          <input
            type="email"
            value={emailAddress}
            placeholder="Email"
            onChange={(e) => setEmailAddress(e.target.value)}
            className="border-b border-gray-300 py-2 text-base text-black mb-4 w-full px-2 rounded-xl"
          />
          {error && <p className="text-sm text-red-500 mb-4 text-center">{error}</p>}
          {message && <p className="text-sm text-green-500 mb-4 text-center">{message}</p>}
          <button onClick={onSubmit} className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-3 mb-6 w-full rounded-full">Send Password</button>
          <p className="text-sm text-center">Remembered your password? <a href="/signin" className="text-black font-bold">Sign In</a></p>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
