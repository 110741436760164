import React, { useEffect, useState } from "react";
import { useAuth } from "../../hooks/GlobalProvider";
import { Admin_CRUD_TRANSACTIONS_URL } from "../../hooks/APIHooks";
import { Link } from "react-router-dom";
import { RiArrowGoBackFill, RiStore3Fill } from "react-icons/ri";

const AllStoreHistory = () => {
  const { user } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deliveryFilter, setDeliveryFilter] = useState({
    All: true,
    PROCESSING: false,
    Delivered: false,
    Cancelled: false,
  });

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(Admin_CRUD_TRANSACTIONS_URL);
        const data = await response.json();

        if (data.success) {
          setTransactions(data.transactions);
        } else {
          setError("Failed to fetch transactions");
        }
      } catch (error) {
        setError("Error fetching transactions");
      }
      setLoading(false);
    };

    fetchTransactions();
  }, []);

  const handleChange = async (id, newDeliveryStatus) => {
    const confirmed = window.confirm(
      "Are you sure you want to save the changes?"
    );
    if (!confirmed) return;

    const updatedTransactions = transactions.map((transaction) =>
      transaction.id === id
        ? { ...transaction, delivery: newDeliveryStatus }
        : transaction
    );

    setTransactions(updatedTransactions);

    const transaction = updatedTransactions.find((t) => t.id === id);

    try {
      const response = await fetch(`${Admin_CRUD_TRANSACTIONS_URL}/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(transaction),
      });

      const data = await response.json();

      if (!data.success) {
        alert("Failed to update transaction");
      }
    } catch (error) {
      alert("Error updating transaction");
    }
  };

  const calculateTotalReceivedGold = (transactions) => {
    return transactions
      .filter((transaction) => transaction.shortName === "BAT_StoreOrders")
      .reduce((sum, transaction) => sum + parseFloat(transaction.orderlist), 0);
  };

  const calculateTotalCompletedAmount = (transactions) => {
    return transactions
      .filter(
        (transaction) =>
          transaction.shortName === "BAT_StoreOrders" &&
          transaction.status.toLowerCase() === "completed"
      )
      .reduce((sum, transaction) => sum + parseFloat(transaction.amount), 0)
      .toFixed(2);
  };

  const filteredTransactions = transactions.filter((transaction) =>
    deliveryFilter.All || deliveryFilter[transaction.delivery]
  );

  const totalReceivedGold = calculateTotalReceivedGold(filteredTransactions);
  const totalCompletedAmount = calculateTotalCompletedAmount(filteredTransactions);

  const renderTable = (transactions) => (
    <div className="bg-white shadow-lg rounded-md p-6 mb-6">
      <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">
        Store History
      </h2>
      <div className="mb-4 flex justify-center space-x-4">
        <button
          onClick={() => setDeliveryFilter({
            All: true,
            PROCESSING: false,
            Delivered: false,
            Cancelled: false,
          })}
          className={`px-4 py-2 rounded-md text-white ${
            deliveryFilter.All ? 'bg-yellow-500' : 'bg-gray-400'
          }`}
        >
          All
        </button>
        <button
          onClick={() => setDeliveryFilter({
            All: false,
            PROCESSING: true,
            Delivered: false,
            Cancelled: false,
          })}
          className={`px-4 py-2 rounded-md text-white ${
            deliveryFilter.PROCESSING ? 'bg-yellow-500' : 'bg-gray-400'
          }`}
        >
          Processing
        </button>
        <button
          onClick={() => setDeliveryFilter({
            All: false,
            PROCESSING: false,
            Delivered: true,
            Cancelled: false,
          })}
          className={`px-4 py-2 rounded-md text-white ${
            deliveryFilter.Delivered ? 'bg-yellow-500' : 'bg-gray-400'
          }`}
        >
          Delivered
        </button>
        <button
          onClick={() => setDeliveryFilter({
            All: false,
            PROCESSING: false,
            Delivered: false,
            Cancelled: true,
          })}
          className={`px-4 py-2 rounded-md text-white ${
            deliveryFilter.Cancelled ? 'bg-yellow-500' : 'bg-gray-400'
          }`}
        >
          Cancelled
        </button>
      </div>
      <div className="grid grid-cols-2 gap-4 text-center mb-4">
        <div className="shadow-lg p-4 rounded-md bg-yellow-500">
          <h3 className="font-semibold text-sm">Total Received Gold:</h3>
          <p className="text-xl font-bold">{totalReceivedGold} grams</p>
        </div>
        <div className="shadow-lg p-4 rounded-md bg-yellow-500">
          <h3 className="font-semibold text-sm">Total Completed Amount:</h3>
          <p className="text-xl font-bold">{totalCompletedAmount} ₹</p>
        </div>
      </div>
      {transactions.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <tbody>
              {transactions
                .filter((transaction) => transaction.shortName === "BAT_StoreOrders")
                .map((transaction) => (
                  <tr key={transaction.id} className="border-b">
                    <td className="grid grid-cols-2 gap-4 p-4 text-black">
                      <div className="text-[14px]">
                        Paid: <br />
                        {transaction.amount}
                        <br />
                        <td
                          className={`text-[10px] ${
                            transaction.status.toLowerCase() === "completed"
                              ? "text-green-500"
                              : "text-red-500"
                          }`}
                        >
                          {transaction.status}
                        </td>
                      </div>
                      <div className="text-[12px]">
                        Received Gold: <br />
                        {transaction.orderlist}
                      </div>
                      <div className="text-[10px] font-bold">
                        Transaction ID: <br />
                        {transaction.transactionId}
                        <br />
                        {transaction.created_at}
                      </div>
                      <div className="text-[10px]">
                        {transaction.email}
                        <br />
                        {transaction.mobileNumber}
                      </div>
                    </td>
                    <td className="py-2 px-1 text-[10px] text-gray-700">
                      <select
                        value={transaction.delivery}
                        onChange={(e) =>
                          handleChange(transaction.id, e.target.value)
                        }
                      >
                        <option value="PROCESSING">PROCESSING</option>
                        <option value="Delivered">Delivered</option>
                        <option value="Cancelled">Cancelled</option>
                      </select>
                      <a
                        href={`/invoice?transactionId=${transaction.transactionId}&message=${transaction.message}&quantity=${transaction.quantity}&amount=${transaction.amount}&email=${transaction.email}&mobileNumber=${transaction.mobileNumber}&orderlist=${transaction.orderlist}&createdAt=${transaction.created_at}`}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        View More
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-gray-600">No store transactions found.</p>
      )}
    </div>
  );

  return (
    <section className="p-2 bg-gray-100 min-h-screen py-20">
      {loading ? (
        <div className="flex items-center justify-center h-screen text-red-500">
          Loading...
        </div>
      ) : error ? (
        <div className="flex items-center justify-center h-screen text-red-500">
          {error}
        </div>
      ) : (
        renderTable(filteredTransactions)
      )}
      {/* Navigation Links */}
      <div className="flex justify-center fixed bottom-0 w-full p-2 z-10">
        <div className="flex flex-row justify-around w-full max-w-md p-2 bg-yellow-500 mx-2 rounded-full">
          <div className="flex flex-col items-center group">
            <Link
              to="/user/dashboard"
              className="flex flex-col items-center px-4 rounded-full text-white"
            >
              <RiArrowGoBackFill className="h-6 w-6 mb-1 text-white" />
              <span className="text-[10px]">BACK</span>
            </Link>
          </div>
          <div className="flex flex-col items-center group">
            <Link
              to="/user/store"
              className="flex flex-col items-center px-4 rounded-full text-white"
            >
              <RiStore3Fill className="h-6 w-6 mb-1 text-white" />
              <span className="text-[10px]">STORE</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllStoreHistory;
