import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  StoreBanner,
  ProductsBanner,
  GoldCoinsCover,
  BlackSmith,
  Referral,
  SilverCoinsCover,
  BISHallmarkBlack,
} from "../../assets/data/Imagedata";
import { FaQuestionCircle } from "react-icons/fa";
import { cover1, cover2, cover3, cover4 } from "../../assets/data/Imagedata";
import { Link } from "react-router-dom";

import { FaStoreAlt } from "react-icons/fa";

const Store = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState("All");

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          "https://batchugold.com/apis/Store/ProductPost.php"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch products");
        }
        const data = await response.json();

        // Filter out products with "Gold Coin" and "Silver Coin" categories
        const filteredProducts = data.filter(
          (product) =>
            product.product_catalogue !== "Gold Coin" &&
            product.product_catalogue !== "Silver Coin"
        );

        // Set products in state
        setProducts(filteredProducts);

        // Extract unique categories from filtered products
        const uniqueCategories = Array.from(
          new Set(
            filteredProducts.map((product) =>
              product.product_catalogue.toLowerCase()
            )
          )
        );

        // Set categories in state, including "All"
        setCategories(["All", ...uniqueCategories]);
      } catch (error) {
        console.error("Error fetching products:", error);
        // Handle error or show a message to the user
      }
    };

    fetchProducts();
  }, []); // Fetch products and categories only once on component mount

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "5%",
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Auto scroll every 3 seconds
  };

  const slidesData = [
    {
      Text: "SAVE",
      description: "Save in 24K Gold, 10₹.",
      background: cover1,
    },
    {
      Text: "EARN",
      description: "Get 100% Referral on every Purchase",
      background: cover3,
    },
    {
      Text: "GROW",
      description: "Automate Your Savings Daily, 24 karat gold.",
      background: cover4,
    },
  ];

  const filteredProducts =
    filter === "All"
      ? products // Show all products except "Gold Coin" and "Silver Coin"
      : products.filter(
          (item) =>
            item.product_catalogue.toLowerCase() === filter.toLowerCase()
        );

  return (
    <section className="my-16 sm:my-20 w-full overflow-hidden">
      <div className="">
        <Slider {...settings}>
          {slidesData.map((slide, index) => (
            <div key={index} className="p-2">
              <div
                className="rounded-lg shadow-md p-4 h-[30vh] sm:h-[40vh]"
                style={{
                  backgroundImage: `url(${slide.background})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="text-center mt-20">
                  <div className="text-white font-bold mt-2 text-[30px]">
                    {slide.Text}
                  </div>
                  <div className="text-white text-[15px]">
                    {slide.description}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <img
        title="Store"
        src={StoreBanner}
        alt="storebanner"
        className="w-[70%] justify-center mx-auto  md:w-[30%] h-auto drop-shadow-md"
      />
      {/* Coins */}
      <section>
        <p className=" text-[12px]  text-black leading-tight text-center mx-auto p-2">
          Introducing the exclusive Pure Gold Coin by "The Gold Jar" – a symbol
          of luxury and refinement. Crafted with precision and elegance, this
          exquisite coin exudes timeless beauty and sophistication. Properly
          packed. Elevate your collection with this rare and precious piece,
          showcasing the finest craftsmanship and purity of gold. Whether as a
          valuable investment or a cherished gift, experience the epitome of
          opulence with this exclusive gold coin from "The Gold Jar."
        </p>

        <h2 className="text-[25px] font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 via-yellow-500 to-orange-500 leading-tight text-center mx-auto drop-shadow-xl">
          SAVE EARN & GROW
        </h2>

        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
          <Link
            to="/Store/GoldCoins"
            className="relative rounded-lg shadow-md h-[23vh] sm:h-[40vh]"
            style={{
              backgroundImage: `url("${GoldCoinsCover}")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="absolute bottom-0 w-full text-center bg-opacity-50 bg-black p-2">
              <h2 className="text-[14px] font-bold text-white">Gold Coins</h2>
            </div>
          </Link>

          <Link
            to=""
            className="relative rounded-lg shadow-md h-[23vh] sm:h-[40vh]"
            style={{
              backgroundImage: `url("${SilverCoinsCover}")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="absolute bottom-0 w-full h-full text-center bg-opacity-50 bg-black p-2">
              <h2 className="text-[14px] font-bold text-white pt-20">
                Coming Soon
              </h2>
              <h2 className="text-[14px] font-bold text-white">Silver Coin</h2>
            </div>
          </Link>

          <Link
            to="/Store/referralstore"
            className="relative rounded-lg shadow-md h-[23vh] sm:h-[40vh]"
            style={{
              backgroundImage: `url("${Referral}")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="absolute bottom-0 w-full text-center bg-opacity-50 bg-black p-2">
              <h2 className="text-[14px] font-bold text-white">
                Referral Store
              </h2>
            </div>
          </Link>

          <Link
            to="/Store/goldsmelter"
            className="relative rounded-lg shadow-md h-[23vh] sm:h-[40vh]"
            style={{
              backgroundImage: `url("${BlackSmith}")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="absolute bottom-0 w-full text-center bg-opacity-50 bg-black p-2">
              <h2 className="text-[14px] font-bold text-white">Gold Smith</h2>
            </div>
          </Link>
        </div>
      </section>

      <section>
        <div className="p-4 overflow-hidden ">
          <div className="flex justify-center">
            <img
              title="Products"
              src={ProductsBanner}
              alt="storebanner"
              className="w-[70%]  md:w-[30%] h-auto drop-shadow-md"
              style={{ zIndex: 1 }}
            />
          </div>
          <div className="flex justify-center space-x-3 ">
            <img
              src={BISHallmarkBlack}
              alt="GoldCoin_BAT"
              className="fit w-[60px] relative"
            />

            <div className="help-icon p-2 text-yellow-500 drop-shadow-xl">
              <a
                href={`https://wa.me/919519519581?text=${encodeURIComponent(
                  "Hello, I need some help."
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaQuestionCircle size={24} />
                <a className="text-[10px] text-black font-black">Help</a>
              </a>
            </div>
          </div>

          <p className="text-[14px] text-black leading-tight text-center mx-auto p-2 w-12/12">
            All items displayed are for illustrative purposes only. For actual
            pricing,  <span className="font-black">please contact us for a quote.</span>
            <br />
            <span className="font-black">Note:</span> "Exchange these 24K gold coins for BIS hallmarked jewelry for
            wholesale prices at our partner stores, share the purchase invoice,
            and receive a bonus of up to ₹1000 in your account."
          </p>

          <div className="w-full overflow-x-auto">
  <div className="flex space-x-2 mb-4 text-[15px] min-w-max">
    {categories.map((category, index) => (
      <button
        key={index}
        onClick={() => setFilter(category)}
        className={`flex ${
          filter.toLowerCase() === category.toLowerCase()
            ? "bg-yellow-700 text-white"
            : "bg-yellow-500 text-black"
        } px-4 py-2 rounded-xl`}
      >
        {category.toLowerCase() === "all" && (
          <FaStoreAlt className="mr-3" size={23} />
        )}
        {category}
      </button>
    ))}
  </div>
</div>


          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {filteredProducts.map((product) => (
              <div
                key={product.id}
                className="relative rounded-lg  cursor-pointer"
                onClick={() => {
                  const phoneNumber = "+919519519581";
                  const message = `Hi, I need information about | Title: ${product.title}\nCatalogue: ${product.product_catalogue}`;
                  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
                    message
                  )}`;
                  window.open(whatsappUrl, "_blank");
                }}
              >
                <div
                  className="h-[23vh] sm:h-[40vh] w-full"
                  style={{
                    backgroundImage: `url(data:image/jpeg;base64,${product.image_data})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
                <div className="w-full text-center p-2 ">
                  <h2 className="text-[14px] font-bold text-black">
                    {product.title}
                  </h2>
                  <p className="text-[10px] text-black font-bold">
                    {product.product_catalogue}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </section>
  );
};

export default Store;
