import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SINGUP_API } from '../../hooks/APIHooks';
import { MarketPlacebg } from '../../assets/data/Imagedata';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Signup = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [address, setAddress] = useState("");
  const [referralCode, setReferralCode] = useState(""); // New state for referral code
  const [error, setError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  // Error states for each field
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [addressError, setAddressError] = useState("");

  // Check if all the required fields are filled and email contains '@'
  useEffect(() => {
    if (firstName && lastName && emailAddress.includes('@') && phoneNumber && password.length > 6 && address) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [firstName, lastName, emailAddress, phoneNumber, password, address]);



  const validateFields = () => {
    let isValid = true;

    // First Name validation
    if (!firstName) {
      setFirstNameError("First name is required");
      isValid = false;
    } else {
      setFirstNameError("");
    }

    // Last Name validation
    if (!lastName) {
      setLastNameError("Last name is required");
      isValid = false;
    } else {
      setLastNameError("");
    }

    // Email validation
    if (!emailAddress) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!emailAddress.includes('@')) {
      setEmailError("Please enter a valid email address");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Phone Number validation (only digits allowed)
    const phoneNumberPattern = /^[0-9]*$/;
    if (!phoneNumber) {
      setPhoneError("Phone number is required");
      isValid = false;
    } else if (!phoneNumberPattern.test(phoneNumber)) {
      setPhoneError("Phone number must contain only digits");
      isValid = false;
    } else {
      setPhoneError("");
    }

    // Password validation
    if (password.length <= 6) {
      setPasswordError("Password must be more than 6 characters");
      isValid = false;
    } else {
      setPasswordError("");
    }

    // Address validation
    if (!address) {
      setAddressError("Address is required");
      isValid = false;
    } else {
      setAddressError("");
    }

    return isValid;
  };

  const onSignUpPress = async () => {
    if (!validateFields()) return; // Validate fields before submitting

    setError(""); // Reset error state before starting
    try {
      const response = await fetch(SINGUP_API, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: `${firstName} ${lastName}`,
          emailaddress: emailAddress,
          phonenumber: phoneNumber,
          sex: 'Male', // Assuming default to "Male"
          role: 'customer',
          password: password,
          address: address,
          referralsignup: referralCode, 
        })
      });

      const data = await response.json();

      if (response.ok) {
        if (data.status === 'success') {
          navigate('/signin');
        } else {
          setError(data.message);
        }
      } else {
        setError('Failed to sign up. Please try again.');
      }
    } catch (err) {
      console.error("Sign-up error", err);
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <section className="py-16 flex items-center justify-center bg-gradient-to-r from-yellow-950 via-red-800 to-yellow-950" style={{
      backgroundImage: `url("${MarketPlacebg}")`,
      backgroundSize: "cover",
      backgroundPosition: "top",
    }}>
      <div className="flex flex-col items-center justify-center min-h-screen p-4">
        <div className="w-full max-w-sm bg-white p-6 rounded-lg shadow-md">
          <h1 className="text-lg font-bold text-center">Let's Start</h1>
          <h2 className="text-xl font-bold text-center">Save Grow & Earn</h2>
          <p className="text-xs text-gray-500 mt-2 text-center">100% Referral Commission . 100% Transparency</p>

          <input
            type="text"
            value={firstName}
            placeholder="First Name"
            onChange={(e) => setFirstName(e.target.value)}
            className="border-b p-2 border-gray-300 py-2 text-base text-black mt-4 w-full"
          />
          {firstNameError && <p className="text-sm text-red-500 mt-1">{firstNameError}</p>}

          <input
            type="text"
            value={lastName}
            placeholder="Last Name"
            onChange={(e) => setLastName(e.target.value)}
            className="border-b p-2 border-gray-300 py-2 text-base text-black mt-4 w-full"
          />
          {lastNameError && <p className="text-sm text-red-500 mt-1">{lastNameError}</p>}

          <input
            type="email"
            value={emailAddress}
            placeholder="Email"
            onChange={(e) => setEmailAddress(e.target.value)}
            className="border-b p-2 border-gray-300 py-2 text-base text-black mt-4 w-full"
          />
          {emailError && <p className="text-sm text-red-500 mt-1">{emailError}</p>}

          <input
            type="tel"
            value={phoneNumber}
            placeholder="Phone Number"
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="border-b p-2 border-gray-300 py-2 text-base text-black mt-4 w-full"
            pattern="[0-9]*" // Ensures only numbers are input
          />
          {phoneError && <p className="text-sm text-red-500 mt-1">{phoneError}</p>}

          <input
            type="text"
            value={address}
            placeholder="Address"
            onChange={(e) => setAddress(e.target.value)}
            className="border-b p-2 border-gray-300 py-2 text-base text-black mt-4 w-full"
          />
          {addressError && <p className="text-sm text-red-500 mt-1">{addressError}</p>}

          {/* Referral Code Input */}
          <div className='mt-4 font-bold text-[14px]'>
          <label >Referral Code</label>
          <input
            type="text"
            value={referralCode}
            placeholder="Referral Code (Optional)"
            onChange={(e) => setReferralCode(e.target.value)}
            className="border-b p-2 border-gray-300 py-2 text-base text-black  w-full"
          /></div>

          <div className="relative mt-4 w-full">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              placeholder="Password (at least 6 characters)"
              onChange={(e) => setPassword(e.target.value)}
              className="border-b p-2 border-gray-300 py-2 text-base text-black w-full px-2 rounded-xl"
            />
            {passwordError && <p className="text-sm text-red-500 mt-1">{passwordError}</p>}
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-4 top-3 focus:outline-none"
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </button>
          </div>

          <button
            onClick={onSignUpPress}
            disabled={!isFormValid}
            className="mt-6 py-2 px-4 bg-yellow-500 text-white rounded-lg w-full font-semibold transition-all duration-300 ease-in-out hover:bg-yellow-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Sign Up
          </button>

          {error && <p className="text-sm text-red-500 mt-4">{error}</p>}

          <p className="text-sm text-center mt-4">
            Already have an account?{" "}
            <Link to="/signin" className="text-yellow-500 hover:text-yellow-700">
              Sign In
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Signup;
