import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/GlobalProvider";

const SubscriptionComponent = () => {
  const { user } = useAuth();
  const [amount, setAmount] = useState(); // Default amount
  const [phone, setPhone] = useState(user.phonenumber);
  const [userId, setUserId] = useState(1);
  const [email, setEmail] = useState(user.emailaddress);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubscription = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const result = await axios.post(
        "https://batchugold.com/apis/PhonePe/DigitalGold/Daily/Dailysubscription.php",
        {
          subscribe: true,
          amount: amount, // Use user input
          plan_id: "1", // Replace with actual plan ID
          phone: phone, // Use user input
          user_id: userId, // Use user input
          email: email, // Use user input
          recurring_type: "DAILY",
        }
      );

      setResponse(result.data);
      setError(null); // Reset error state

      // Check for successful subscription
      if (result.data.insert_id) {
        // If insert_id is returned, use it for navigation
        const insertId = result.data.insert_id;
        // Also, ensure that the subscriptionId is correctly extracted
        const subscriptionId = result.data.subscriptionId; // Adjust if the key is different

        navigate(
          `/MarketPlace/verifyupi?insert_id=${insertId}&phonenumber=${phone}&emailaddress=${email}&amount=${amount}&subscriptionId=${subscriptionId}`
        );
      }
    } catch (err) {
      setError(err.response.data || "An error occurred");
      setResponse(null); // Reset response state
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white rounded-lg shadow-lg mt-20">
      <h2 className="text-2xl font-bold mb-4 text-center">
        Subscribe to Daily Gold
      </h2>
      <form onSubmit={handleSubscription}>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Amount:
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
            />
          </label>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Phone:
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              readOnly
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-200"
            />
          </label>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Email:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              readOnly
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-200"
            />
          </label>
        </div>
        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md shadow hover:bg-blue-700 transition duration-200"
        >
          Subscribe
        </button>
      </form>

      {response && (
        <div className="mt-4 p-4 border border-green-500 bg-green-100 rounded-md">
          <h3 className="font-semibold text-green-800">
            Status: {response.status}
          </h3>
          <p>Message: {response.message}</p>
          <p>Insert ID: {response.insert_id}</p>
        </div>
      )}
      {error && (
        <div className="mt-4 p-4 border border-red-500 bg-red-100 rounded-md">
          <h3 className="font-semibold text-red-800">Error:</h3>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default SubscriptionComponent;
