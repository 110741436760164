import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/GlobalProvider";
import { RiStore2Line, RiHome2Line, RiAccountCircleLine } from "react-icons/ri";
import { GiAnt, GiGoldBar } from "react-icons/gi";
import { FaMoneyBillWave } from "react-icons/fa";

function BottomNavbar() {
  const location = useLocation();
  const [circlePos, setCirclePos] = useState({ left: 0 });
  const navRef = useRef();

  useEffect(() => {
    const navElement = navRef.current;
    if (navElement) {
      const activeLink = navElement.querySelector(".active");
      if (activeLink) {
        const rect = activeLink.getBoundingClientRect();
        const navRect = navElement.getBoundingClientRect();
        setCirclePos({ left: rect.left - navRect.left + rect.width / 2 - 20 });
      }
    }
  }, [location.pathname]);

  const getLinkClasses = (path) => {
    return location.pathname === path
      ? "active text-white"
      : "text-black group-hover:text-yellow-500";
  };

  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut();
    navigate("/signin");
  };

  const isVisible = [
    "/Store",
    "/contactus",
    "/MarketPlace",
    "/",
    "/aboutus",
    "/user/dashboard",
    "/user/currency",
    "/signup",
    "/signin",
    "/forgot-password",
  ].includes(location.pathname);

  if (!isVisible) {
    return null;
  }

  return (
    <nav className="fixed bottom-0 w-full z-[100] drop-shadow-2xl">
      <div className="flex justify-center relative">
        <div
          ref={navRef}
          className="flex flex-row justify-around w-full sm:max-w-md py-2 px-2 border-t-4 border-white bg-white sm:rounded-xl relative z-10"
        >
          {/* Store */}
          <div className="flex flex-col items-center group">
            <Link
              to="/Store"
              className={`flex flex-col items-center px-4 rounded-full ${getLinkClasses(
                "/Store"
              )}`}
            >
              <RiStore2Line
                className={`${
                  location.pathname === "/Store"
                    ? "text-yellow-500 z-10 text-[28px]"
                    : "text-black z-10 text-[24px]"
                }`}
              />
              <span className="text-[10px] text-black">Store</span>
            </Link>
          </div>

          {/* Market */}
          <div className="flex flex-col items-center group">
            <Link
              to="/MarketPlace"
              className={`flex flex-col items-center px-4 rounded-full ${getLinkClasses(
                "/MarketPlace"
              )}`}
            >
              <GiGoldBar
                className={`${
                  location.pathname === "/MarketPlace"
                    ? "text-yellow-500 z-10 text-[28px]"
                    : "text-black z-10 text-[24px]"
                }`}
              />
              <span className="text-[10px] text-black">Market</span>
            </Link>
          </div>

          {/* Home */}
          <div className="flex flex-col items-center group">
            <Link
              to="/"
              className={`flex flex-col items-center px-4 rounded-full ${getLinkClasses(
                "/"
              )}`}
            >
              <RiHome2Line
                className={`${
                  location.pathname === "/"
                    ? "text-yellow-500 z-10 text-[28px]"
                    : "text-black z-10 text-[24px]"
                }`}
              />
              <span className="text-[10px] text-black">Home</span>
            </Link>
          </div>

          {/* About */}
          {user ? (
            <div className="flex flex-col items-center group">
              <Link
                to="/aboutus"
                className={`flex flex-col items-center px-4 rounded-full ${getLinkClasses(
                  "/aboutus"
                )}`}
              >
                <GiAnt
                  className={`${
                    location.pathname === "/aboutus"
                      ? "text-yellow-500 z-10 text-[28px]"
                      : "text-black z-10 text-[24px]"
                  }`}
                />
                <span className="text-[10px] text-black">About</span>
              </Link>
            </div>
          ) : (
            <div className="flex flex-col items-center group">
              <Link
                to="/aboutus"
                className={`flex flex-col items-center px-4 rounded-full ${getLinkClasses(
                  "/aboutus"
                )}`}
              >
                <GiAnt
                  className={`${
                    location.pathname === "/aboutus"
                      ? "text-yellow-500 z-10 text-[28px]"
                      : "text-black z-10 text-[24px]"
                  }`}
                />
                <span className="text-[10px] text-black">About</span>
              </Link>
            </div>
          )}

          {/* Account / Signup */}
          {user ? (
            <div className="flex flex-col items-center group">
              <Link
                to="/user/dashboard"
                className={`flex flex-col items-center px-4 rounded-full ${getLinkClasses(
                  "/user/dashboard"
                )}`}
              >
                <RiAccountCircleLine
                  className={`${
                    location.pathname === "/user/dashboard"
                      ? "text-yellow-500 z-10 text-[28px]"
                      : "text-black z-10 text-[24px]"
                  }`}
                />
                <span className="text-[10px] text-black">Account</span>
              </Link>
            </div>
          ) : (
            <div className="flex flex-col items-center group">
              <Link
                to="/signup"
                className={`flex flex-col items-center px-5 rounded-full ${getLinkClasses(
                  "/signup"
                )}`}
              >
                <RiAccountCircleLine
                  className={`${
                    location.pathname === "/signup"
                      ? "text-yellow-500 z-10 text-[28px]"
                      : "text-black z-10 text-[24px]"
                  }`}
                />
                <span className="text-[10px] text-black">Signup</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default BottomNavbar;
