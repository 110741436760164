import React, { useEffect, useState } from "react";
import axios from "axios";
import { Admin_CRUD_SUBSCRIPTION_URL } from "../../hooks/APIHooks";
import { useAuth } from "../../hooks/GlobalProvider";

const SubscriptionList = () => {
  const { user } = useAuth();
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [deleteError, setDeleteError] = useState(null);

  useEffect(() => {
    // Check if user is an admin
    if (user && user.role === "admin") {
      axios
        .get(Admin_CRUD_SUBSCRIPTION_URL)
        .then((response) => {
          setSubscriptions(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setError("Failed to fetch subscriptions");
          setLoading(false);
        });
    } else {
      setError("Unauthorized access"); // Set error for unauthorized access
      setLoading(false);
    }
  }, [user]);

  const handleViewClick = (subscription) => {
    setSelectedSubscription(subscription);
    setDeleteError(null);
  };

  const handleDeleteClick = (subscriptionId) => {
    if (window.confirm("Are you sure you want to delete this subscription?")) {
      axios
        .delete(`${Admin_CRUD_SUBSCRIPTION_URL}?id=${subscriptionId}`)
        .then(() => {
          setSubscriptions(
            subscriptions.filter((sub) => sub.id !== subscriptionId)
          );
          setSelectedSubscription(null);
        })
        .catch((err) => {
          setDeleteError("Failed to delete the subscription.");
        });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mx-auto p-4 mt-20">
      <h1 className="text-2xl font-bold mb-4">Subscription List</h1>
      <table className="min-w-full table-auto">
        <thead>
          <tr className="bg-gray-200">
            <th className="px-4 py-2">ID</th>
            <th className="px-4 py-2">User ID</th>
            <th className="px-4 py-2">Plan ID</th>
            <th className="px-4 py-2">Subscription ID</th>
            <th className="px-4 py-2">Recurring Type</th>
            <th className="px-4 py-2">Start Date</th>
            <th className="px-4 py-2">End Date</th>
            <th className="px-4 py-2">Amount</th>
            <th className="px-4 py-2">Status</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.length > 0 ? (
            subscriptions.map((subscription) => (
              <tr key={subscription.id} className="border-b">
                <td className="px-4 py-2">{subscription.id}</td>
                <td className="px-4 py-2">{subscription.user_id}</td>
                <td className="px-4 py-2">{subscription.plan_id}</td>
                <td className="px-4 py-2">{subscription.subscriptionId}</td>
                <td className="px-4 py-2">{subscription.recurring_type}</td>
                <td className="px-4 py-2">
                  {new Date(subscription.start_date).toLocaleDateString()}
                </td>
                <td className="px-4 py-2">
                  {new Date(subscription.end_date).toLocaleDateString()}
                </td>
                <td className="px-4 py-2">{subscription.amount}</td>
                <td className="px-4 py-2">{subscription.status}</td>
                <td className="px-4 py-2">
                  <button
                    className="bg-blue-500 text-white px-2 py-1 rounded"
                    onClick={() => handleViewClick(subscription)}
                  >
                    View
                  </button>
                  <button
                    className="bg-red-500 text-white px-2 py-1 rounded ml-2"
                    onClick={() => handleDeleteClick(subscription.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center py-4">
                No subscriptions found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {selectedSubscription && (
        <div className="mt-10 p-4 border rounded bg-gray-100">
          <h2 className="text-xl font-bold">Subscription Details</h2>
          <p>
            <strong>ID:</strong> {selectedSubscription.id}
          </p>
          <p>
            <strong>User ID:</strong> {selectedSubscription.user_id}
          </p>
          <p>
            <strong>Plan ID:</strong> {selectedSubscription.plan_id}
          </p>
          <p>
            <strong>Subscription ID:</strong>{" "}
            {selectedSubscription.subscriptionId}
          </p>
          <p>
            <strong>Recurring Type:</strong>{" "}
            {selectedSubscription.recurring_type}
          </p>
          <p>
            <strong>Start Date:</strong>{" "}
            {new Date(selectedSubscription.start_date).toLocaleDateString()}
          </p>
          <p>
            <strong>End Date:</strong>{" "}
            {new Date(selectedSubscription.end_date).toLocaleDateString()}
          </p>
          <p>
            <strong>Amount:</strong> {selectedSubscription.amount}
          </p>
          <p>
            <strong>UPI:</strong> {selectedSubscription.upi}
          </p>
          <p>
            <strong>Status:</strong> {selectedSubscription.status}
          </p>
          <p>
            <strong>Email:</strong> {selectedSubscription.email}
          </p>
          <p>
            <strong>Transaction ID:</strong>{" "}
            {selectedSubscription.transactionId}
          </p>
          <p>
            <strong>Notification Status:</strong>{" "}
            {selectedSubscription.notification_status}
          </p>
          <p>
            <strong>Debit Execute Status:</strong>{" "}
            {selectedSubscription.debit_execute_status}
          </p>
          <p>
            <strong>Auto Debit Status:</strong>{" "}
            {selectedSubscription.auto_debit_status}
          </p>
          <p>
            <strong>Cancel Status:</strong> {selectedSubscription.cancel_status}
          </p>

          {/* Error handling for delete */}
          {deleteError && <p className="text-red-500 mt-4">{deleteError}</p>}
        </div>
      )}
    </div>
  );
};

export default SubscriptionList;
