import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../hooks/GlobalProvider";
import { USER_CURRENCY_ANTS } from "../../hooks/APIHooks";
import { Link, useLocation } from "react-router-dom";

import { Ant,Goldraw } from "../../assets/data/Imagedata";
const UserCurrency = () => {
  const { user } = useAuth();
  const [currency, setCurrency] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  const fetchCurrency = async (email) => {
    try {
      const response = await axios.get(
        `${USER_CURRENCY_ANTS}?emailaddress=${email}`
      );
      setCurrency(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user || !user.emailaddress) return;
    setLoading(true);
    fetchCurrency(user.emailaddress);
  }, [user, location]);

  if (loading)
    return (
      <Link to="/user/currency">
  <div className="items-center space-y-2 rounded-l-full">
    <div className="relative h-full w-[10vw] items-center justify-end">
      <div className="relative z-10 text-right">
       
          <div className="bg-white/70 text-center p-1 rounded-l-xl">
            <img
              src={Ant}
              alt="Gold"
              className="w-[50px] h-auto rounded-full justify-center mx-auto"
            />
            <p className="text-[8px] text-black font-bold">Start Earning</p>
          </div>
     
      </div>
    </div>
  </div>
</Link>

    );
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Link to='/user/currency'>
    <div className="items-center space-y-1 rounded-l-full ">


      <div className="relative h-full w-[10vw] sm:w-[5vw]  items-center justify-end ">
        <div className="relative z-10 text-right">
          {currency ? (
            <div className="bg-white/70 text-center p-1 rounded-l-xl">
            <img
              src={Ant}
              alt="Gold"
              className="w-[50px]  h-auto rounded-full justify-center mx-auto"
            />
              <p className="text-black text-[8px] font-bold">{currency.ants}</p>
              <p className="text-[8px] text-black font-bold">Ants</p>
            </div>
          ) : (
            <p className="text-[10px] text-black">0 Earn</p>
          )}
        </div>
      </div>



      <div className="relative  h-full w-[10vw] sm:w-[5vw]  items-center justify-end">
        <div className="relative z-10 text-right">
          {currency ? (
            <div className="bg-white/70 text-center p-1 rounded-l-xl">
            <img
              src={Goldraw}
              alt="Gold"
              className="w-[50px] h-auto rounded-full justify-center mx-auto"
            />
              <p className="text-black text-[8px] font-bold">{parseFloat(currency.gold_grams).toFixed(3)}</p>
              <p className="text-[8px] text-black font-bold">Gold</p>
            </div>
          ) : (
            <p className="text-[10px] text-black">0 Earn</p>
          )}
        </div>
      </div>

    
    
  

    </div>
    </Link>
  );
};

export default UserCurrency;
